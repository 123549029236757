.invernada{
  margin: auto;
  margin-top: 100px;
  padding: 32px;
	@include mq(mobile, tablet) {
		padding: 32px 0;
	}
  @media screen and (max-width: $bp_ipad){
    margin-top: 0;
  }
  &_layout{
    display: flex;

		@include mq(mobile,desktop) {
			flex-direction: column;
		}
    flex-wrap: wrap;
    gap: 32px;
    justify-content: center;
    //margin-top: 60px;

		@include mq(mobile, tablet) {
			margin-top: 20px
		}

    @media screen and (max-width: $bp_imac){
        flex-wrap: nowrap;
    }

    &_filters{
      background-color: #ddd;
      background-color: white;
      width: 100%;
      top: 118px;
      position: fixed;
      z-index: 3;
      border: 1px solid #ddd;
      border-width: 0 0 1px 0;
			left: 0;
			@include mq(mobile, tablet) {
				top: 120px;
			}

			@include mq(tablet, desktop) {
				top: 118px;
			}
    }

    &_list{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.invernada_card{
  width: 100%;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 32px 54px 500px 1fr;
  grid-template-rows: 32px 1fr 100px;
  grid-template-areas:
    "info orden imagen timer"
    "info orden imagen data"
    "info orden imagen btns";
  &_principal{
    border: 1px solid lightgrey;
    border-width: 0px 0px 1px 0px;
    display: flex;
    padding: 16px;
    justify-content: space-between;
    @media screen and (max-width: $bp_ipad){
      flex-direction: column-reverse;
      gap: 32px;

    }
    &_label{
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 12px;
    }
    &_value{
      font-weight: bold;
      font-size: 14px;
      color: $statusGreen;
    }
  }
  &_secundaria{
    padding: 16px;
    display: flex;
    border: 1px solid lightgrey;
    border-width: 0px 0px 1px 0px;
    gap: 16px;
    flex-wrap: wrap;
    &_bloque{
      width: 200px;
    }
  }
  &_description{
    padding: 16px;
  }
  &_info{
    grid-area: info;
    background-color: $verdeOscuro;
    color: white;
    font-size: 14px;
    text-align: center;
    width: 100%;
    height: 100%;

    p{
      transform: rotate(-90deg);
      width: 300px;
    }
  }
  &_order{
    grid-area: orden;
  }
  &_img{
    grid-area: imagen;
  }
  &_timer{
    grid-area: timer;

  }
  &_data{
    grid-area: data;
  }
  &_btns{
    grid-area: btns;
  }
}

.buttonView {
  padding: 10px 15px;
  border-radius: 5px;
  background: grey;
  color: white !important;
  margin-right: 10px;
  cursor: pointer;
}

.button-list-view-wrapper {
  display: flex;
  flex: 100%;
  justify-content: center;
}

.invernada2{
  margin: auto;
  margin-top: 10px;
  margin-bottom: 32px;
  border-radius: 16px;
  overflow: hidden;
  background-color: white;
  width: 100%;
  max-width: 1200px;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 32px 64px 400px auto;
  grid-template-rows: 32px auto 80px;
  grid-template-areas:
  "status named img timer"
  "status named img body"
  "status named img btns";
  @media screen and (max-width: $bp_imac){
    grid-template-columns: 1fr;
    grid-template-rows: 32px 32px 400px 32px auto 80px;
    grid-template-areas:
    "status"
    "named"
    "img"
    "timer"
    "body"
    "btns";
  }

  &_status{
    background: linear-gradient(-45deg, grey, lightgrey);
  	background-size: 400% 400%;
  	animation: gradient .5s ease infinite;

    grid-area: status;
    @media screen and (max-width: $bp_imac){
      div{
        p{
          transform: none;
          margin-bottom: 0;
          text-align: center;
          width: 100%;

        }
      }
    }

  }
  &_name{
    background-color: white;
    grid-area: named;
    position: relative;
    p{
      transform: rotate(-90deg);
      margin: auto;
      position: absolute;
      bottom: calc(50% + 50px);
      left: -68px;
      width: 200px;
      @media screen and (max-width: $bp_imac){
        transform: none;
        text-align: center;
        width: 100%;
        left:0px !important;
        top:6px;
      }
    }

  }
  &_image{
    background-color: #ddd;
    grid-area: img;
    img{
      width: 100%;
      object-fit: contain;
    }
  }
  &_timer{
    background-color: $statusGreen;
    grid-area: timer;
    color: white;
    text-align: center;
  }
  &_body{
    background-color: white;
    grid-area: body;
  }
  &_btns{
    background-color: white;
    grid-area: btns;
  }
}

.filter{
  padding: 22px 48px;
  display: flex;
  gap: 48px;
  row-gap: 12px;
  justify-content: center;
  align-items: center;
  //flex-wrap: wrap;
	@include mq(mobile, tablet) {
		flex-wrap: wrap;
	}
  &_input{
    width: 200px !important;
		@include mq(mobile, tablet) {
			width: 100% !important;
		}
		@include mq(tablet, desktop) {
			width: 130px !important;
		}
    //@media screen and (max-width: $bp_ipad){
    //  width: 90vw !important;
    //}
  }
  //@media screen and (max-width: $bp_imac){
  //  //flex-wrap: wrap;
  //  gap: 16px;
  //  justify-content: center;
  //}
  &_group{
    display: flex;
    gap: 16px;
		@include mq(mobile, tablet) {
			flex-direction: column;
			width: 100%;
		}
    //@media screen and (max-width: $bp_ipad){
    //  flex-wrap: wrap;
    //}
  }
  &_submit{
    display: flex;
    gap: 16px;
    @media screen and (max-width: $bp_ipad){
      width: 100vw;
    }
  }
  &_openbtn{
    text-align: center;
    height: 40px;
    align-items: center;
    display: none;
    //padding-top: 51px;
    @include mq(mobile, tablet) {
      display: block;
    }
  }
  &_icon{
    width: 40px;
    color: red
  }
}

.btn_filter{
  margin-left: 0;
  padding-top: 0 !important;
  @media screen and (max-width: $bp_ipad){
    width: 100% !important;
  }

}
.filterHide{
  margin-top: 0px;
	@include mq(mobile, tablet) {
		top: -200px;
	}
  //@media screen and (max-width: $bp_ipad){
  //  margin-top: -350px;
  //}
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}
@keyframes gradient {
	0% {
		background-position: 0% 75%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.viewActive {
  background: #19513c !important;
}

.status-active {
  background: #66a38c !important;
  color: white !important;
  font-weight: 600;
}

.status-finished {
  background: #f96161 !important;
  color: white !important;
  font-weight: 600;
}

.status-initial {
  background: #bbbbbb !important;
  color: white !important;
  font-weight: 600;
}

.lote-props-mobile-wrapper {
	padding: 0 20px 0;
}

.lote-props-wrapper {
	display: flex;
	flex-wrap: wrap;
	padding: 15px 20px 0;

	@include mq(mobile, desktop) {
		justify-content: flex-start;
		flex-wrap: wrap;
		padding: 0 5%;
		gap: 1.5rem;
		margin-top: 2rem;
	}
	@include mq(tablet, desktop) {
		justify-content: space-between;
		margin-top: 1rem;
	}
}

.lote-prop-mobile-obs-block {
	margin-bottom: 1.5rem;
}

.lote-prop-mobile-block {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-bottom: 1rem;
	grid-gap: 18px;
}

.lote-prop-block {
	@include mq(desktop, wide) {
		flex: 0 0 25%;
	}

	@include mq(wide) {
		flex: 0 0 25%;
	}
}

.lotes-props-invernada {
	display: block;
}

.property-mobile-invernada-flex {
	display: flex;
	flex-direction: column;
	gap: 5px;
	font-size: 13px;

	> p {
		@include mq(mobile, tablet) {
			font-size: 13px;
		}
		&:nth-of-type(1) {
			font-weight: 700;
		}
	}
}

.property-invernada-flex {
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-bottom: 10px;
	font-size: 13px;

	@include mq(desktop, wide) {
		font-size: 12px;
		gap: 0;
	}
	@include mq(tablet, desktop) {
		font-size: 12px;
		gap: 0;
	}

	> p {
		@include mq(mobile, tablet) {
			font-size: 13px;
		}
		&:nth-of-type(1) {
			font-weight: 700;
		}
	}
}
