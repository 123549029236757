@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap);
@charset "UTF-8";
* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "DM Sans", sans-serif;
  background-color: #EEEEEE;
  color: #545454;
}

h2 {
  color: white;
  font-size: 72px;
  line-height: 120%;
}
@media screen and (max-width: 1080px) {
  h2 {
    font-size: 56px;
    line-height: 120%;
  }
}

.notFound {
  height: 80vh;
}

button {
  border: none;
}

.breadcrum {
  width: 100%;
  text-align: left;
  position: fixed;
  background-color: #9fb5ad;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 3;
  top: 115px;
  left: 0;
  color: white;
  padding-left: 32px;
  box-sizing: border-box;
  font-size: 14px;
}

.developmentMode {
  background-color: #ffbe5c !important;
}

.navbar {
  z-index: 20;
  height: 84px;
  background-color: #19513C;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;
  transition: height 0.4s;
}
@media screen and (max-width: 428px) {
  .navbar {
    padding: 0 8px;
  }
}
.navbar div {
  min-height: 20px;
}
.navbar_rrss {
  z-index: 4;
  display: flex;
  justify-content: flex-start;
  grid-gap: 16px;
  gap: 16px;
  width: 352px;
}
.navbar_rrss a {
  position: static;
  z-index: 2;
}
@media screen and (max-width: 1080px) {
  .navbar_rrss {
    width: 200px;
    display: none;
  }
}
@media screen and (max-width: 834px) {
  .navbar_rrss {
    display: none;
  }
}
.navbar_rrss-icon {
  position: relative;
  -webkit-transform: scale(1);
          transform: scale(1);
  z-index: 1;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  cursor: pointer;
}
.navbar_rrss-icon:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: brightness(70%);
}
.navbar_logo {
  display: inline-flex;
  margin: auto;
  margin-left: auto;
  box-sizing: border-box;
  height: 90%;
  padding: 16px 16px 16px 16px;
  transition: margin-left 0.4s;
}
@media screen and (max-width: 1080px) {
  .navbar_logo {
    margin: inherit;
  }
}
.navbar_logo a {
  justify-content: center;
}
@media screen and (max-width: 1080px) {
  .navbar_logo a {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 834px) {
  .navbar_logo {
    margin-left: 0;
  }
}
.navbar_logo:hover {
  -webkit-filter: brightness(70%);
}
.navbar_login {
  width: 352px;
}
.navbar_logo a {
  display: inline-flex;
  z-index: 1;
}
.navbar_logo_img {
  object-fit: cover;
  height: auto;
  cursor: pointer;
}
@media screen and (max-width: 428px) {
  .navbar_logo_img {
    display: none;
  }
}
.navbar_logo_img-small {
  display: none;
}
@media screen and (max-width: 428px) {
  .navbar_logo_img-small {
    display: block;
  }
}
.navbar_botonera {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  -webkit-animation-name: show;
          animation-name: show;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
@media screen and (max-width: 834px) {
  .navbar_botonera {
    justify-content: flex-end;
  }
}
.navbar_hamburger {
  width: 200px;
  justify-content: flex-end;
  display: none;
}
@media screen and (max-width: 1080px) {
  .navbar_hamburger {
    display: none;
  }
}
.navbar_user {
  font-size: 12px;
  color: white;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  -webkit-animation-name: show;
          animation-name: show;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.navbar_user p {
  line-height: 2;
}
.navbar_user p b {
  color: #FF9900;
}

@-webkit-keyframes show {
  from {
    margin-bottom: 120px;
    opacity: 0;
  }
  to {
    margin-bottom: 0px;
    opacity: 1;
  }
}

@keyframes show {
  from {
    margin-bottom: 120px;
    opacity: 0;
  }
  to {
    margin-bottom: 0px;
    opacity: 1;
  }
}
/*navbar*/
.navbar_menu {
  min-width: 100%;
  height: 32px;
  background-color: #839F95;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  z-index: 9999;
}
.navbar_menu ul {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  list-style: none;
  color: white;
}

.navbar_fixed {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 99;
}

hr {
  width: 100%;
}

.navbar2_desplegable {
  background-color: #19513C;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 110px;
  padding: 20px;
  color: white;
  right: 35px;
  width: 200px;
  z-index: 999;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 16px 0 16px 16px;
}
.navbar2_desplegable-disabled {
  display: none;
}
.navbar2_desplegable-punta {
  position: absolute;
  top: -16px;
  right: 6px;
  width: 30px;
  height: 30px;
  background-color: #19513C;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.navbar2_desplegable_superior {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}
.navbar2_desplegable hr {
  margin: 10px 0;
}
.navbar2_desplegable h2 {
  font-size: 20px;
  text-align: center;
  word-break: break-all;
}
.navbar2_userBtn {
  padding: 10px 0 !important;
  height: initial !important;
}
.navbar2_avatar {
  width: 50px;
  height: 50px;
  margin-top: 15px;
  background-color: #FF9900;
  border-radius: 50%;
  color: white;
  box-sizing: border-box;
  padding-top: 8px;
  font-size: 25px;
  margin-left: 26px;
  text-align: center;
  cursor: pointer;
}
.navbar2_avatar:hover {
  opacity: 0.8;
}
.navbar2_avatar-offline {
  background-color: #bbb;
}

.navbar2_flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.navbar2_logo {
  height: 84px;
  margin: 0;
}
.navbar2_logo img {
  height: 100%;
}

.breadcrum {
  top: 84px;
  opacity: 0.8;
  padding-left: 48px !important;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .breadcrum {
    padding-left: 5px !important;
  }
}

.navbar2_menu {
  color: white;
  padding-top: 32px;
  font-size: 14px;
}
.navbar2_menu_burger {
  padding-top: 28px;
  display: none;
}
@media (min-width: 20em) and (max-width: 68.74em) {
  .navbar2_menu_burger {
    display: block;
  }
}
@media (min-width: 20em) and (max-width: 68.74em) {
  .navbar2_menu-active {
    display: block !important;
  }
}
@media (min-width: 20em) and (max-width: 68.74em) {
  .navbar2_menu {
    display: none;
    position: absolute;
    background-color: #103e2d;
    top: 84px;
    left: 0;
    width: 100%;
    padding: 16px;
    padding-left: 0px;
  }
}
.navbar2_menu ul {
  display: flex;
  align-items: center;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .navbar2_menu ul {
    text-align: center;
    display: block;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .navbar2_menu ul {
    text-align: center;
    display: block;
  }
}
@media screen and (max-width: 834px) {
  .navbar2_menu ul {
    justify-content: center;
  }
}
.navbar2_menu ul li {
  list-style: none;
  margin-right: 16px;
  margin-left: 16px;
  align-self: center;
  padding-bottom: 8px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .navbar2_menu ul li {
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
  }
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .navbar2_menu ul li > a {
    display: block;
    font-size: 16px;
    padding: 8px 0 !important;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .navbar2_menu ul li > a {
    display: block;
    font-size: 16px;
    padding: 8px 0 !important;
  }
}

.navbar2_menu_flex {
  display: flex;
}

@media (min-width: 20em) and (max-width: 46.24em) {
  .litle-space-menu {
    padding-bottom: 0;
    font-size: 16px;
  }
}

.li-submenu {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .li-submenu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}

footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-top: 60px;
}

.footer {
  background-color: #19513C;
  box-sizing: border-box;
  width: 100%;
  padding: 36px;
  display: flex;
  padding-left: 64px;
  padding-right: 64px;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  font-size: 14px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .footer {
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .footer {
    grid-gap: 30px;
    gap: 30px;
    padding: 30px;
  }
}
.footer_title {
  margin-bottom: 16px;
  font-weight: bold;
}
.footer_text {
  color: #66A38C;
}
.footer_logo {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}
@media (min-width: 20em) and (max-width: 68.74em) {
  .footer_logo {
    justify-content: center;
  }
}
.footer_link {
  grid-gap: 200px;
  gap: 200px;
  line-height: 2;
  text-align: left;
  color: white;
}
.footer_link ul {
  color: white;
  list-style: none;
}
.footer_icon {
  width: 12px;
  margin-right: 8px;
}

.sub-footer {
  background-color: #66A38C;
  height: 48px;
  color: white;
  font-weight: lighter;
  font-size: 12px;
  text-align: center;
  padding-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.sub-footer img {
  height: 35px;
}

.navbar_rrss-icon-png {
  height: 32px;
}

.hero {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 120px 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  gap: 32px;
  background-position: center center;
  background-size: cover;
}
@media screen and (max-width: 1080px) {
  .hero {
    grid-gap: 16px;
    gap: 16px;
  }
}
@media screen and (max-width: 834px) {
  .hero {
    padding: 80px 24px;
  }
}
@media screen and (max-width: 428px) {
  .hero {
    padding: 48px 8px;
  }
}
.hero .btn {
  margin-left: 0;
}
.hero_parragraph {
  color: white;
  min-height: 62px;
  font-size: 20px;
  max-width: 700px;
  line-height: 156%;
}
@media screen and (max-width: 1080px) {
  .hero_parragraph {
    font-size: 16px;
    max-width: 500px;
  }
}
@media screen and (max-width: 428px) {
  .hero_parragraph {
    font-size: 16px;
  }
}
.hero_title {
  min-height: 172px;
  color: white;
  max-width: 800px;
  font-size: 72px;
  line-height: 120%;
}
@media screen and (max-width: 1080px) {
  .hero_title {
    font-size: 56px;
    line-height: 120%;
    max-width: 600px;
  }
}
@media screen and (max-width: 428px) {
  .hero_title {
    font-size: 40px;
  }
}
.hero img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heroBanner_img-lg {
  display: block;
}
@media screen and (max-width: 1080px) {
  .heroBanner_img-lg {
    display: none;
  }
}
.heroBanner_img-md {
  display: none;
}
@media screen and (max-width: 1080px) {
  .heroBanner_img-md {
    display: block;
  }
}
@media screen and (max-width: 834px) {
  .heroBanner_img-md {
    display: none;
  }
}
.heroBanner_img-sm {
  display: none;
}
@media screen and (max-width: 834px) {
  .heroBanner_img-sm {
    display: block;
  }
}
.heroBanner img {
  width: 100%;
}

.card1 {
  padding: 32px;
  padding-top: 8px;
  padding-bottom: 0;
  margin-top: 32px;
  cursor: pointer;
  max-width: 900px;
  margin: auto;
  margin-bottom: 2rem;
}
.card1_container {
  box-sizing: border-box;
  overflow: hidden;
  background-color: grey;
  width: 100%;
  max-width: 1200px;
  min-height: 300px;
  margin: auto;
  overflow: hidden;
  border-radius: 18px;
  display: grid;
  display: -webkit-grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: 32px 1fr;
  grid-template-areas: "img status" "img text";
}
@media screen and (max-width: 834px) {
  .card1_container {
    grid-template-columns: 100%;
    grid-template-rows: 32px 300px 250px;
    grid-template-areas: "status" "img" "text";
  }
}
@media screen and (max-width: 630px) {
  .card1_container {
    grid-template-columns: 100%;
    grid-template-rows: 32px 300px minmax(350px, auto);
    grid-template-areas: "status" "img" "text";
  }
}
.card1_container_img {
  background-color: white;
  grid-area: img;
  display: flex;
  justify-content: center;
  background-color: #ddd;
}
.card1_container_img img {
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
@media screen and (max-width: 834px) {
  .card1_container_img img {
    height: 100%;
    width: auto;
  }
}
.card1_container_status {
  color: white;
  background-color: #F96161;
  padding-left: 16px;
  padding-top: 8px;
  grid-area: status;
  font-size: 12px;
}
.card1_container_text {
  padding: 16px;
  padding-top: 32px;
  padding-bottom: 40px;
  background-color: white;
  grid-area: text;
  color: #545454;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (max-width: 834px) {
  .card1_container_text {
    padding-top: 30px;
  }
}
.card1_container_text_cajaSuperior {
  width: 100%;
}
@media screen and (max-width: 834px) {
  .card1_container_text_cajaSuperior {
    min-width: 100%;
  }
}
.card1_container_text_cajaInferior {
  align-self: flex-end;
}
.card1_container_text-cliente {
  font-size: 22px;
  width: 100%;
}
.card1_container_text-titulo {
  font-size: 28px;
  font-weight: 700;
  line-height: 100%;
  margin-top: 16px;
  margin-bottom: 32px;
  width: 100%;
}
@media screen and (max-width: 834px) {
  .card1_container_text-titulo {
    margin-top: 8px;
    margin-bottom: 32px;
  }
}
.card1_container_text-fecha {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 8px;
}
@media screen and (max-width: 834px) {
  .card1_container_text-fecha {
    font-size: 16px;
  }
}
.card1_container_text-tiempo {
  font-size: 17px;
  color: #FF9900;
}

.statusGrey {
  background-color: #BDBDBD;
}

.statusGreen {
  background-color: #66A38C;
}

.statusRed {
  background-color: #F96161;
}

.remates_list {
  margin-top: 50px;
  margin-bottom: 50px;
}

.card2 {
  padding: 8px;
  margin-top: 23px;
}
.card2_container {
  overflow: hidden;
  border-radius: 8px;
  margin: auto;
  background-color: white;
  width: 100%;
  max-width: 1064px;
  display: grid;
  grid-template-columns: 389px 1fr 1fr 1fr 1fr;
  grid-template-rows: 32px 100px 100px 1fr;
  grid-template-areas: "lote timer timer timer timer" "img titulo titulo precio precio" "img caracteristica caracteristica caracteristica caracteristica" "img botonera botonera botonera botonera";
}
@media screen and (max-width: 1080px) {
  .card2_container {
    grid-template-columns: 389px 1fr 1fr 1fr 1fr;
    grid-template-rows: 32px 32px 100px 75px 100px;
    grid-template-areas: "lote timer timer timer timer" "img precio precio precio precio" "img titulo titulo titulo titulo" "img caracteristica caracteristica caracteristica caracteristica" "img botonera botonera botonera botonera";
  }
}
@media screen and (max-width: 834px) {
  .card2_container {
    max-width: 450px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 32px 32px 200px 32px 100px 100px 100px;
    grid-template-areas: "timer timer" "lote lote" "img img" "precio precio" "titulo titulo" "caracteristica caracteristica" "botonera botonera";
  }
}
.card2_container_lote {
  background-color: #19513C;
  color: white;
  font-weight: 700;
  grid-area: lote;
  text-align: center;
  padding-left: 8px;
  padding-right: 9px;
  padding-top: 7px;
}
.card2_container_img {
  background-color: blue;
  grid-area: img;
  overflow: hidden;
}
.card2_container_img img {
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .card2_container_img img {
    height: auto;
    width: 100%;
  }
}
.card2_container_timer {
  color: white;
  background-color: #BDBDBD;
  grid-area: timer;
  text-align: right;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 7px;
}
.card2_container_titulo {
  background-color: white;
  color: #333333;
  font-size: 24px;
  padding: 8px;
  padding-top: 26px;
  font-weight: bold;
  grid-area: titulo;
}
.card2_container_precio {
  color: #333333;
  background-color: white;
  grid-area: precio;
  padding: 8px;
  padding-top: 26px;
  text-align: right;
}
@media screen and (max-width: 1080px) {
  .card2_container_precio {
    text-align: left;
  }
}
.card2_container_caracteristica {
  padding: 8px;
  font-size: 12px;
  background-color: white;
  grid-area: caracteristica;
  display: flex;
  flex-wrap: wrap;
  height: 12px;
  grid-gap: 8px;
  gap: 8px;
  justify-content: flex-start;
}
.card2_container_caracteristica-item {
  min-width: 120px;
}
.card2_container_botonera {
  background-color: white;
  display: flex;
  flex-wrap: nowrap;
  grid-area: botonera;
  padding: 8px;
}
@media screen and (max-width: 1080px) {
  .card2_container_botonera {
    flex-wrap: wrap;
  }
}
.card2_container_btn {
  color: white;
  margin-right: 16px;
  margin-bottom: 8px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  padding-top: 10px;
  height: 48px;
  border-radius: 8px;
  background-color: orange;
  width: 100%;
  max-width: 318px;
  padding-top: 12px;
}
@media screen and (max-width: 1080px) {
  .card2_container_btn {
    max-width: 100%;
  }
}

.lotesBig {
  width: 100%;
  padding: 8px 32px 8px 32px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 428px) {
  .lotesBig {
    padding: 8px 16px 8px 16px;
  }
}
.lotesBig_portada {
  margin: auto;
  margin-top: 65px;
  width: calc(100% - 32px);
  max-width: 1000px;
  border-radius: 16px;
}
.lotesBig_portada img {
  width: 100%;
  height: 100%;
  max-height: 550px;
  object-fit: contain;
  object-position: center center;
}
.lotesBig_card {
  background-color: white;
  max-width: 1000px;
  width: 100%;
  height: 100%;
  min-height: 250px;
  border-radius: 16px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 32px 64px 2fr 4fr;
  grid-template-rows: 25px 90px auto 1fr;
  grid-template-areas: "status order img timer" "status order img principal" "status order img secundaria" "status order img botonera";
}
@media screen and (max-width: 1080px) {
  .lotesBig_card {
    grid-template-columns: 48px 60px 1fr;
    grid-template-rows: 32px 200px 120px auto auto;
    grid-template-areas: "status order timer" "status order img" "status order principal" "status order secundaria" "status order botonera";
  }
}
@media screen and (max-width: 834px) {
  .lotesBig_card {
    grid-template-columns: 32px 32px 1fr;
    grid-template-rows: 32px 200px auto auto 90px;
  }
}
.lotesBig_card-multi {
  grid-template-columns: 48px 60px 1fr;
  grid-template-rows: 32px 300px 120px auto auto;
  grid-template-areas: "status order timer" "status order img" "status order principal" "status order secundaria" "status order botonera";
}
@media screen and (max-width: 834px) {
  .lotesBig_card-multi {
    grid-template-columns: 32px 32px 1fr;
    grid-template-rows: 32px 200px auto auto 110px;
  }
}
.lotesBig_card_status {
  width: 100%;
  height: 100%;
  background-color: #BDBDBD;
  grid-area: status;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.lotesBig_card_status-activo {
  background-color: #66A38C;
}
.lotesBig_card_status-terminado {
  background-color: #F96161;
}
.lotesBig_card_status p {
  margin-bottom: -46px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  color: white;
}
.lotesBig_card_timer {
  width: 100%;
  color: white;
  font-size: 14px;
  padding-top: 4px;
  grid-area: timer;
  text-align: center;
}
.lotesBig_card_timer-activo {
  background-color: #66A38C;
}
.lotesBig_card_timer-desactivado {
  background-color: #F96161;
}
.lotesBig_card_order {
  background-color: white;
  grid-area: order;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  border: 1px solid #EEE;
  border-width: 0 1px 0 0;
}
.lotesBig_card_img {
  grid-area: img;
  background-color: #ddd;
}
.lotesBig_card_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.lotesBig_card_infoPrincipal {
  position: relative;
  background-color: white;
  padding: 47px 32px 32px;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  justify-content: space-between;
  grid-area: principal;
  border: 1px solid #EEE;
  border-width: 0 0 1px 0;
}
@media screen and (max-width: 428px) {
  .lotesBig_card_infoPrincipal {
    flex-wrap: wrap;
    padding: 32px;
    grid-gap: 35px;
    gap: 35px;
    flex-direction: column-reverse;
  }
}
.lotesBig_card_infoPrincipal_bloques {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 16px;
}
@media screen and (max-width: 834px) {
  .lotesBig_card_infoPrincipal_bloques {
    width: 40%;
  }
}
.lotesBig_card_infoPrincipal_bloques_titulo {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #545454;
}
.lotesBig_card_infoPrincipal_bloques_descripcion {
  color: #66A38C;
  margin-top: 12px;
  text-transform: capitalize;
}
@media screen and (max-width: 834px) {
  .lotesBig_card_infoPrincipal {
    flex-wrap: wrap;
  }
}
.lotesBig_card_infoSecundariaTable {
  background-color: white;
  grid-area: secundaria;
  min-height: 100px;
  padding-bottom: 8px;
  font-size: 12px;
  text-transform: uppercase;
  overflow-x: hidden;
  border: 1px solid #EEE;
  padding-bottom: 20px;
  margin-top: 10px;
  border-width: 0 0 1px 0;
  overflow: hidden;
  width: 100%;
}
.lotesBig_card_infoSecundariaTable-multi {
  height: 300px;
}
.lotesBig_card_infoSecundariaTable_caja {
  background-color: white;
  border-radius: 4px;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  grid-gap: 8px;
  gap: 8px;
}
.lotesBig_card_infoSecundariaTable_caja-multi {
  background-color: white;
  border-radius: 4px;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  grid-row-gap: 12px;
  row-gap: 12px;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
  border: 1px solid lightgrey;
  border-width: 0 0 1px 0;
  padding-bottom: 16px;
}
.lotesBig_card_infoSecundariaTable_caja-multi-tabla {
  background-color: lightgrey;
  border-radius: 4px;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  grid-row-gap: 12px;
  row-gap: 12px;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
  border-width: 0 0 1px 0;
  padding: 12px;
  margin-bottom: 10px;
}
@media screen and (max-width: 428px) {
  .lotesBig_card_infoSecundariaTable_caja {
    flex-wrap: nowrap;
  }
}
.lotesBig_card_infoSecundariaTable_caja::after {
  content: "grrrrrrr";
  position: -webkit-sticky;
  position: sticky;
  right: -40px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 70%);
  width: 50px;
  color: transparent;
  display: none;
}
@media screen and (max-width: 834px) {
  .lotesBig_card_infoSecundariaTable_caja::after {
    display: block;
  }
}
.lotesBig_card_infoSecundariaTable_caja_bloque_titulo-tabla {
  font-weight: bold;
  width: 100px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 35px;
}
.lotesBig_card_infoSecundariaTable_caja_bloque_titulo {
  font-weight: bold;
  width: 100px;
  margin-top: 16px;
  margin-bottom: 8px;
}
.lotesBig_card_infoSecundariaTable_caja_bloque_descripcion-tabla {
  width: 100px;
  margin-top: 8px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #66A38C;
}
@media screen and (max_width: 428px) {
  .lotesBig_card_infoSecundariaTable_caja_bloque_descripcion-tabla {
    font-size: 13px;
  }
}
.lotesBig_card_infoSecundariaTable_caja_bloque_descripcion {
  width: 100px;
  margin-top: 8px;
  color: #66A38C;
}
.lotesBig_card_infoSecundaria {
  background-color: white;
  grid-area: secundaria;
  min-height: 100px;
  padding: 32px;
  padding-top: 0;
  padding-bottom: 8px;
  font-size: 12px;
  text-transform: uppercase;
  overflow-x: hidden;
  border: 1px solid #EEE;
  padding-bottom: 20px;
  border-width: 0 0 1px 0;
  overflow: hidden;
}
.lotesBig_card_infoSecundaria-multi {
  height: 300px;
}
.lotesBig_card_infoSecundaria_caja {
  background-color: white;
  border-radius: 4px;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  grid-gap: 8px;
  gap: 8px;
}
.lotesBig_card_infoSecundaria_caja-multi {
  background-color: white;
  border-radius: 4px;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  grid-row-gap: 12px;
  row-gap: 12px;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
  border: 1px solid lightgrey;
  border-width: 0 0 1px 0;
  padding-bottom: 16px;
}
.lotesBig_card_infoSecundaria_caja-multi-tabla {
  background-color: rgba(243, 243, 243, 0.568627451);
  border-radius: 4px;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-width: 0 0 1px 0;
  padding: 12px 0;
  margin-bottom: 10px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .lotesBig_card_infoSecundaria_caja-multi-tabla {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
}
@media (min-width: 46.25em) {
  .lotesBig_card_infoSecundaria_caja-multi-tabla {
    display: flex;
    grid-row-gap: 12px;
    row-gap: 12px;
    grid-column-gap: 8px;
    -webkit-column-gap: 8px;
            column-gap: 8px;
  }
}
@media screen and (max-width: 428px) {
  .lotesBig_card_infoSecundaria_caja {
    flex-wrap: nowrap;
  }
}
.lotesBig_card_infoSecundaria_caja::after {
  content: "grrrrrrr";
  position: -webkit-sticky;
  position: sticky;
  right: -40px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 70%);
  width: 50px;
  color: transparent;
  display: none;
}
@media screen and (max-width: 834px) {
  .lotesBig_card_infoSecundaria_caja::after {
    display: block;
  }
}
.lotesBig_card_infoSecundaria_caja_bloque_titulo-tabla {
  font-weight: bold;
  width: 100px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 35px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .lotesBig_card_infoSecundaria_caja_bloque_titulo-tabla {
    font-size: 11px;
  }
}
@media (min-width: 46.25em) {
  .lotesBig_card_infoSecundaria_caja_bloque_titulo-tabla {
    font-size: 12px;
  }
}
.lotesBig_card_infoSecundaria_caja_bloque_titulo {
  font-weight: bold;
  width: 100px;
  margin-top: 16px;
  margin-bottom: 8px;
}
.lotesBig_card_infoSecundaria_caja_bloque_descripcion-tabla {
  width: 100px;
  margin-top: 8px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #66A38C;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .lotesBig_card_infoSecundaria_caja_bloque_descripcion-tabla {
    font-size: 12px;
  }
}
@media (min-width: 46.25em) {
  .lotesBig_card_infoSecundaria_caja_bloque_descripcion-tabla {
    font-size: 12px;
  }
}
.lotesBig_card_infoSecundaria_caja_bloque_descripcion {
  width: 100px;
  margin-top: 8px;
  color: #66A38C;
}
.lotesBig_card_botonera {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: white;
  grid-area: botonera;
}
.lotesBig_card_botonera_multi {
  width: 100%;
  text-align: center;
  padding: 0 0 16px 0;
  cursor: pointer;
  text-decoration: underline;
  color: #FF9900;
}

.expand {
  height: 100% !important;
}

.numeracion {
  height: 200%;
}

.numer {
  color: blue;
  height: 100%;
  grid-area: numberOrder;
}

.btn-table {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid white;
  border-radius: 4px;
  text-align: center;
  color: white;
  padding: 8px 15px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 600;
  margin-left: 4px;
  margin-right: 4px;
}
@media screen and (max-width: 428px) {
  .btn-table {
    font-size: 14px;
    padding: 10px 20px;
  }
}

.btn-table-viewMore {
  background-color: #19513C;
}

@media screen and (max-width: 428px) {
  td.alignRightMobile {
    text-align: right;
  }
}

.buyoutCaption {
  background-color: #4d79d3;
  color: white !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  min-height: 58px !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media screen and (max-width: 428px) {
  .buyoutCaption {
    min-height: 30px !important;
    font-size: 13px !important;
  }
}

.divCaptionBuyout {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #4d79d3;
  padding: 10px 20px;
  color: white !important;
  font-size: 12px;
  font-weight: 700;
}

@media screen and (max-width: 428px) and (max-width: 834px) {
  .tableContainer {
    overflow: hidden;
  }
  .noContentTable:before {
    content: "" !important;
  }
  .status-initial, .status-active, .status-finished {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-weight: 600 !important;
    text-transform: uppercase;
  }
  .noPaddingMobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .tableLoteList {
    width: 100%;
  }
  .buyoutCaption {
    min-height: 65px !important;
  }
  .status-finished {
    font-size: 11px;
    padding: 0 40px !important;
  }
}
/*
	table {
		display: block !important;
	}
	thead {
		display: block !important;
		tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
	}
	tbody {
		display: block !important;
	}
	th {
		display: block !important;
	}
	td {
		display: block !important;
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
    font-size: 13px !important;
		padding-left: 40% !important;

		&:before {
			position: absolute;
			top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
		}
		&:nth-of-type(1) {
			&:before {
				content: "Animales";
        text-align: left;
        padding-top: 15px;
        font-weight: 600;
			}
		}
		&:nth-of-type(2) {
			&:before {
				content: "";
			}
		}
		&:nth-of-type(3) {
			&:before {
				content: "Lote";
        text-align: left;
        padding-top: 5px;
        font-weight: 600;
			}
		}
		&:nth-of-type(4) {
			&:before {
				content: "Propietario";
        text-align: left;
        padding: 5px 0;
        font-weight: 600;
        font-size: 13px;
			}
		}
		&:nth-of-type(5) {
			&:before {
				content: "Descripción";
        text-align: left;
        padding: 5px 0;

        font-weight: 600;
        font-size: 13px;
			}
		}
		&:nth-of-type(6) {
			&:before {
				content: "Última oferta x Cuota x Animal";
        text-align: left;
        padding: 5px 0;
        font-weight: 600;
        font-size: 13px;

			}
		}
		&:nth-of-type(7) {
			&:before {
				content: "Ofertante";
        text-align: left;
        padding: 5px 0;
        font-weight: 600;
        font-size: 13px;
			}
		}
	}

	tr {
		display: block !important;
		margin: 0 0 1rem 0;
	}
}
*/
.btn {
  cursor: pointer;
  display: inline-block;
  width: 144px;
  height: 40px;
  border: 1px solid white;
  border-radius: 4px;
  text-align: center;
  color: white;
  padding-top: 12px;
  box-sizing: border-box;
  font-size: 14px;
  margin-left: 4px;
  margin-right: 4px;
  transition: all 0.3s ease-in-out;
}

.btn-list {
  cursor: pointer;
  display: inline-block;
  width: 85px;
  border: 1px solid white;
  border-radius: 4px;
  text-align: center;
  color: white;
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 11px;
  margin-left: 4px;
  margin-right: 5px;
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
}

.btn-invernada {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media screen and (max-width: 500px) {
  .btn-invernada {
    width: 135px !important;
  }
}

.btn-invernada-list {
  margin-left: 0 !important;
}
@media screen and (max-width: 500px) {
  .btn-invernada-list {
    width: 135px !important;
  }
}

.invernada_hitbox {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.btn-long {
  width: 47%;
}

@media screen and (max-width: 500px) {
  .btn {
    width: 94px;
  }
}
.btn:hover {
  -webkit-filter: brightness(70%);
}

.btn_cta {
  background-color: #FF9900;
  border: none;
}

.btn_cta_list {
  margin-right: 0 !important;
  background-color: #FF9900;
  border: none;
}

.btn_ctaBorder {
  background-color: none;
  border: 1px solid #FF9900;
  color: #FF9900;
}

.btn-ppl {
  background-color: #19513C;
  border: none;
}

.btn-ppl-list {
  background-color: #19513C;
  border: none;
}

.btn-disabled {
  cursor: not-allowed;
  background-color: #EEE;
}

.hone {
  display: none;
}
@media screen and (max-width: 834px) {
  .hone {
    display: none;
  }
}

.ficha {
  box-sizing: border-box;
  border-radius: 16px;
  max-width: 1200px;
  background-color: white;
  height: auto;
  margin: auto;
  margin-top: 24px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .ficha {
    width: 100%;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .ficha {
    width: 90%;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .ficha {
    width: 80%;
  }
}
@media (min-width: 100em) {
  .ficha {
    width: 70%;
  }
}
.ficha_timer {
  background-color: #19513C;
  margin-left: -54px;
  margin-right: -54px;
  text-align: center;
  color: white;
  height: 32px;
  padding-top: 6px;
  box-sizing: border-box;
}
.ficha_timer-close {
  background-color: #F96161 !important;
}
.ficha_info {
  padding: 12px 0;
  text-align: center;
  font-weight: 600;
  background-color: #19513C;
  color: white;
  border-radius: 3px 3px 0 0;
}
.ficha_info-close {
  background-color: #F96161;
}
.ficha_info-proximamente {
  background-color: #BDBDBD;
}
.ficha_info-open {
  background-color: #66A38C;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .ficha_info {
    font-size: 15px;
  }
}
@media (min-width: 46.25em) {
  .ficha_info {
    font-size: 20px;
  }
}
.ficha_data {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .ficha_data {
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}
@media (min-width: 46.25em) {
  .ficha_data {
    grid-gap: 2rem;
    gap: 2rem;
  }
}
.ficha_data_timer {
  grid-area: timer;
  background-color: #66A38C;
  color: white;
  text-align: center;
  padding-top: 4px;
  box-sizing: border-box;
  height: 32px;
}
.ficha_data_img {
  width: 100%;
}
.ficha_data_img > img {
  width: 100%;
  margin: 0 auto;
}
.ficha_data_text {
  display: flex;
  flex-direction: column;
}
.ficha_data_text_nombre h5 {
  margin-top: 16px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .ficha_data_text_nombre h5 {
    font-size: 30px;
  }
}
@media (min-width: 46.25em) {
  .ficha_data_text_nombre h5 {
    font-size: 34px;
  }
}
.ficha_data_text_puja {
  margin: 10px 0;
}
.ficha_data_text_oferta h5 {
  color: #FF9900;
}
.ficha_wrapp {
  margin-top: 96px;
  padding: 16px;
  display: flex;
}
.ficha_titulo {
  color: #545454;
}
.ficha_titulo_codigo {
  font-size: 16px;
}
.ficha_titulo_codigo_dato {
  font-size: 24px;
  color: #FF9900;
  opacity: 1;
  margin-top: 8px;
}
.ficha_titulo_titular {
  font-size: 35px;
  margin-top: 16px;
  font-weight: bold;
}
.ficha_datosPrincipales {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .ficha_datosPrincipales {
    padding: 0 10px;
  }
}
.ficha_datosPrincipales-cajas {
  width: 290px;
  min-width: 200px;
  margin-top: 16px;
}
.ficha_datosPrincipales-cajas_titulos {
  color: #FF9900;
  font-weight: bold;
}
.ficha_datosPrincipales-cajas_datos {
  color: #545454;
  margin-top: 8px;
}
.ficha_datosSecundarios {
  border: 1px solid #ddd;
  border-width: 1px 1px 1px 1px;
  width: 100%;
  display: grid;
  border-bottom: 0;
}
.ficha_datosSecundarios:first-child {
  border-radius: 16px 16px 0 0;
}
.ficha_datosSecundarios:last-child {
  border-bottom: 1px solid #ddd;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .ficha_datosSecundarios {
    text-align: center;
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
    grid-gap: 1rem 3rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .ficha_datosSecundarios {
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
    grid-gap: 1rem 3rem;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .ficha_datosSecundarios {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 1rem;
    grid-gap: 1rem 3rem;
  }
}
@media (min-width: 100em) {
  .ficha_datosSecundarios {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 1rem;
    grid-gap: 1rem 3rem;
  }
}
.ficha_datosSecundarios_cajas {
  flex: 0 1 30%;
  border-width: 0 0 1px 0;
}
@media screen and (max-width: 1080px) {
  .ficha_datosSecundarios_cajas {
    max-width: 100%;
  }
}
.ficha_datosSecundarios_cajas_titulo {
  color: #19513C;
  font-weight: bold;
  font-size: 12px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .ficha_datosSecundarios_cajas_titulo {
    font-size: 13px;
    margin-bottom: 5px;
  }
}
@media (min-width: 46.25em) {
  .ficha_datosSecundarios_cajas_titulo {
    font-size: 16px;
  }
}
.ficha_datosSecundarios_cajas_dato {
  color: #66A38C;
  font-weight: normal;
  background-color: white !important;
  text-transform: uppercase;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .ficha_datosSecundarios_cajas_dato {
    font-size: 12px;
    width: 70%;
    margin: auto;
  }
}
@media (min-width: 46.25em) {
  .ficha_datosSecundarios_cajas_dato {
    font-size: 18px;
  }
}
.ficha_stats {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.ficha_stats_caja {
  margin: 16px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #545454;
}
.ficha_stats_caja_img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: black;
}
.ficha_stats_caja_titulo {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 1rem;
}
.ficha_stats_caja_texto {
  margin-top: 16px;
  text-align: center;
  font-size: 14px;
  opacity: 0.5;
  text-transform: uppercase;
  line-height: 1.6;
}
@media screen and (max-width: 428px) {
  .ficha_stats_caja_texto {
    padding: 32px;
    font-size: 12px;
  }
}
.ficha_galeria {
  width: 100%;
  margin-top: 32px;
}
.ficha_galeria_titulo {
  color: #545454;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}
.ficha_galeria_caja {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
  margin-top: 24px;
}
.ficha_galeria_caja_foto {
  width: 40%;
}
@media screen and (max-width: 1080px) {
  .ficha_galeria_caja_foto {
    width: 70%;
  }
}
.ficha_galeria_caja_foto img {
  width: 100%;
}
.ficha_botonera {
  display: flex;
  justify-content: center;
  margin: 48px;
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.mass {
  width: 100%;
}

.ficha_data_timer-close {
  background-color: #F96161 !important;
}

.ficha_data_timer-proximamente {
  background-color: #BDBDBD !important;
}

.content-spacing {
  width: 95%;
  margin: 1rem auto;
}

.form {
  box-sizing: border-box;
  color: grey;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  max-width: 1000px;
  border-radius: 8px;
  margin: auto;
  margin-top: 64px;
  margin-bottom: 30px;
  min-height: 400px;
  overflow: hidden;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
.form_passwordReset {
  color: #19513C;
  text-align: center;
  width: 100% !important;
  font-size: 16px;
}
.form_passwordReset span {
  color: #FF9900;
  font-weight: bolder;
}
.form_flex {
  display: flex;
  grid-gap: 34px;
  gap: 34px;
  justify-content: space-between;
}
@media screen and (max-width: 834px) {
  .form_flex {
    flex-direction: column;
  }
}
.form-m {
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 100px;
  margin-top: 75px;
  z-index: 1000;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .form-m {
    margin-left: 3%;
    margin-right: 3%;
  }
}
.form form {
  padding: 16px;
  margin: auto 0;
}
.form_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@media screen and (max-width: 834px) {
  .form {
    grid-template-columns: 1fr;
    grid-template-rows: 300px 1fr;
  }
}
@media screen and (max-width: 834px) {
  .form {
    grid-template-rows: 200px 1fr;
  }
}
.form h3 {
  text-align: left;
  font-size: 32px;
  font-weight: lighter;
  color: #FF9900;
}
.form .input_wide {
  width: 300px;
}
@media screen and (max-width: 834px) {
  .form .input_wide {
    width: 98%;
    margin-left: 0;
    max-width: 100%;
  }
}
.form input {
  padding-left: 8px;
  width: 100%;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 8px;
}
.form input:focus-visible {
  outline-color: #66A38C;
}
.form small {
  height: 12px;
  font-size: 12px;
  color: #F96161;
  border: 1px solid white;
}
.form label {
  margin-top: 32px;
  font-weight: lighter;
  font-size: 14px;
}
.form_box {
  margin-top: 32px;
  margin-bottom: 32px;
}
.form_box_item {
  margin-top: 8px;
}
.form button {
  margin-top: 20px;
  margin-left: auto !important;
  padding-top: 0px;
  width: 100%;
  font-size: 16px;
}

@-webkit-keyframes fadeIn {
  from {
    margin-top: 84px;
    opacity: 0;
  }
  to {
    margin-top: 64px;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    margin-top: 84px;
    opacity: 0;
  }
  to {
    margin-top: 64px;
    opacity: 1;
  }
}
.Ghoast_input {
  pointer-events: none;
  width: 200px;
  height: 20px;
  position: relative;
  background-color: white;
  top: -26px;
  left: 8px;
  z-index: 20px;
}

.whatsapp {
  width: 60px;
  height: 60px;
  background-color: #66A38C;
  position: fixed;
  bottom: 78px;
  right: 20px;
  border-radius: 50%;
  z-index: 999;
}
.whatsapp :hover {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  opacity: 1;
  -webkit-filter: brightness(100%);
}
.whatsapp img {
  width: 100%;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  margin: auto;
}

.snackbar {
  pointer-events: none;
  z-index: 800;
  position: fixed;
  top: 114px;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  overflow: hidden;
  height: 110px;
  color: white;
  margin: auto;
  background-color: transparent !important;
}
.snackbar_message {
  padding: 16px;
  text-align: center;
  border-radius: 8px;
  margin-top: -80px;
  background-color: #66A38C;
}
.snackbar-success {
  background-color: #66A38C;
}
.snackbar-error {
  background-color: #F96161;
}
.snackbar-hd {
  -webkit-animation-name: hidde;
          animation-name: hidde;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes hidde {
  0% {
    margin-top: -80px;
  }
  25% {
    margin-top: 0;
  }
  75% {
    margin-top: 0;
  }
  100% {
    margin-top: -80px;
  }
}

@keyframes hidde {
  0% {
    margin-top: -80px;
  }
  25% {
    margin-top: 0;
  }
  75% {
    margin-top: 0;
  }
  100% {
    margin-top: -80px;
  }
}
.lightbox {
  overflow: scroll;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.lightbox_btn {
  position: fixed;
  right: 40px;
  top: 90px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 9999;
}
.lightbox_card {
  position: relative;
  width: calc(100% - 64px);
  height: 100%;
  max-width: 800px;
  margin: auto;
  margin-top: 100px;
}
.lightbox_card_img {
  width: 100%;
  max-width: 800px;
  max-height: 700px;
}
.lightbox_card_img img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.displayBlock {
  display: block !important;
}

.lb {
  display: none;
}

.puja_status-able {
  background-color: #66A38C;
  color: white;
  padding: 8px;
}
.puja_status-disable {
  background-color: #F96161;
  color: white;
  padding: 8px;
}

.pujaForm {
  margin-bottom: 16px;
}
.pujaForm_container {
  max-width: 1000px;
  background-color: white;
  margin: auto;
  border-radius: 3px;
}
.pujaForm_secondBox {
  display: grid;
  grid-gap: 2rem;
  margin-top: 2rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .pujaForm_secondBox {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .pujaForm_secondBox {
    grid-template-columns: 1.4fr 1fr;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .pujaForm_secondBox {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 100em) {
  .pujaForm_secondBox {
    grid-template-columns: 1fr 1fr;
  }
}
.pujaForm_name {
  box-sizing: border-box;
  text-transform: uppercase;
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 0;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .pujaForm_name {
    font-size: 15px;
    padding: 8px 0;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .pujaForm_name {
    font-size: 22px;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .pujaForm_name {
    font-size: 23px;
  }
}
@media (min-width: 100em) {
  .pujaForm_name {
    font-size: 23px;
  }
}
.pujaForm_infobox {
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .pujaForm_infobox {
    grid-template-columns: 1fr;
    padding: 0;
    grid-gap: 1rem;
    gap: 1rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .pujaForm_infobox {
    grid-template-columns: 1.3fr 1fr;
    padding: 18px 18px 0;
  }
}
@media (min-width: 68.75em) {
  .pujaForm_infobox {
    grid-template-columns: 1fr 1fr;
    padding: 18px 18px 0;
  }
}
.pujaForm_infobox_img {
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .pujaForm_infobox_img {
    width: 100%;
  }
}
@media (min-width: 46.25em) {
  .pujaForm_infobox_img {
    width: 100%;
  }
}
.pujaForm_infobox_img img {
  width: 100%;
  object-fit: contain;
  z-index: 2;
  height: 100%;
}
.pujaForm_infobox_text {
  padding: 0 18px 0;
}
.pujaForm_infobox_text p {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.pujaForm_infobox_text h3 {
  font-weight: 600;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .pujaForm_infobox_text h3 {
    font-size: 24px;
    margin-bottom: 1rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .pujaForm_infobox_text h3 {
    font-size: 25px;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .pujaForm_infobox_text h3 {
    font-size: 27px;
  }
}
@media (min-width: 100em) {
  .pujaForm_infobox_text h3 {
    font-size: 30px;
  }
}
.pujaForm_infobox_status {
  grid-area: status;
}
.pujaForm_status-on {
  color: white;
  height: 32px;
  font-size: 14px;
  padding: 6px;
  box-sizing: border-box;
  text-align: center;
  background-color: #66A38C;
}
.pujaForm_status-off {
  color: white;
  height: 32px;
  font-size: 14px;
  padding: 6px;
  box-sizing: border-box;
  text-align: center;
  background-color: #BDBDBD;
}
.pujaForm_data {
  padding: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  justify-content: flex-start;
  font-size: 14px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .pujaForm_data {
    padding: 0 20px;
  }
}
.pujaForm_data_incremento > p {
  font-weight: 700;
  margin-bottom: 5px;
}
.pujaForm_data_incremento > h5 {
  color: #FF9900;
  font-size: 24px;
}
.pujaForm_data_oferta > p {
  font-weight: 700;
  margin-bottom: 5px;
}
.pujaForm_data_oferta > h5 {
  color: #FF9900;
  font-size: 24px;
}
.pujaForm_data_buyout > p {
  font-weight: 700;
  margin-bottom: 5px;
}
.pujaForm_data_buyout > h5 {
  color: #4d79d3;
  font-size: 24px;
}
.pujaForm_data_yourOffer {
  width: 160px;
  background-color: #FF9900;
  color: white;
  height: 30px;
  text-align: center;
  font-size: 12px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 15px;
}
.pujaForm_data_anotherOffer {
  background-color: #66A38C;
  color: white;
  text-align: center;
  font-size: 15px;
  padding: 10px;
  width: 220px;
  justify-content: center;
  border-radius: 50px;
  display: inline-flex;
  margin: 1rem 0;
}
.pujaForm_input {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.pujaForm_input label {
  display: block;
  font-size: 20px;
  margin-bottom: 10px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .pujaForm_input label {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.pujaForm_input input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 16px;
  border-width: 1px;
  border-style: none;
  border-color: grey !important;
  border: 1px solid red;
  padding-left: 16px;
  font-family: "DM Sans";
  color: grey;
}
.pujaForm_botonera {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
  grid-gap: 1.3rem;
  gap: 1.3rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .pujaForm_botonera {
    width: 90%;
    margin: 1rem auto 0;
  }
}
.pujaForm_botonera-buyout {
  height: 41px;
  background-color: #4d79d3;
  color: white;
  font-size: 15px;
  border-radius: 3px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.pujaForm_botonera-buyout:hover {
  background-color: #2f5ebe;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .pujaForm_botonera-buyout {
    width: 100%;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .pujaForm_botonera-buyout {
    width: 80%;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .pujaForm_botonera-buyout {
    width: 80%;
  }
}
@media (min-width: 100em) {
  .pujaForm_botonera-buyout {
    width: 80%;
  }
}
.pujaForm_botonera-ofertar {
  height: 41px;
  background-color: #FF9900;
  color: white;
  font-size: 15px;
  font-weight: 700;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.pujaForm_botonera-ofertar:hover {
  background-color: #cc7a00;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .pujaForm_botonera-ofertar {
    width: 100%;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .pujaForm_botonera-ofertar {
    width: 80%;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .pujaForm_botonera-ofertar {
    width: 80%;
  }
}
@media (min-width: 100em) {
  .pujaForm_botonera-ofertar {
    width: 80%;
  }
}
.pujaForm_botonera-update {
  text-align: center;
  background-color: #19513C;
  color: white;
  font-size: 15px;
  height: 41px;
  font-weight: 700;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .pujaForm_botonera-update {
    width: 100%;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .pujaForm_botonera-update {
    width: 80%;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .pujaForm_botonera-update {
    width: 80%;
  }
}
@media (min-width: 100em) {
  .pujaForm_botonera-update {
    width: 80%;
  }
}

.userProfile_botonera {
  padding-bottom: 89px;
}
.userProfile_botonera .btn {
  width: 100% !important;
  margin: auto;
  margin-bottom: 16px;
}
.userProfile_container {
  padding-left: 128px;
  padding-right: 128px;
}
@media screen and (max-width: 834px) {
  .userProfile_container {
    padding-left: 28px;
    padding-right: 28px;
  }
}
.userProfile_box {
  max-width: 1472px;
  background-color: white;
  margin: auto;
  margin-top: 126px;
  border-radius: 16px;
  padding-left: 83px;
  padding-right: 83px;
  padding-top: 37px;
  padding-bottom: 83px;
}
@media screen and (max-width: 834px) {
  .userProfile_box {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.userProfile_box_contenido {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 85px;
  width: 100%;
  grid-gap: 12px;
  gap: 12px;
}
@media screen and (max-width: 834px) {
  .userProfile_box_contenido {
    flex-direction: column;
  }
}
.userProfile_box_contenido_bloque {
  width: 48%;
  height: 80px;
  margin-bottom: 32px;
  word-break: break-all;
}
@media screen and (max-width: 834px) {
  .userProfile_box_contenido_bloque {
    width: 100%;
  }
}
.userProfile_box_contenido_bloque_label {
  font-weight: bold;
  color: #545454;
  font-size: 18px;
  margin-bottom: 24px;
}
.userProfile_box_contenido_bloque_value {
  font-size: 14px;
  color: #FF9900;
}
.userProfile_box_contenido_bloque_input {
  width: 100%;
  height: 40px;
  border: 2px solid grey !important;
  padding: 8px;
  box-sizing: border-box;
  color: grey;
  outline-color: grey !important;
}
.userProfile_box_contenido_bloque_input-red {
  border: 2px solid #F96161 !important;
}
.userProfile_box_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 76px;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .userProfile_box_header {
    flex-direction: column;
  }
}
.userProfile_box_header_datos-conf {
  font-size: 16px;
}
.userProfile_box_header_datos-user {
  font-size: 24px;
  font-weight: bold;
  color: #FF9900;
}
.userProfile_box_header_datos h2 {
  color: #545454;
  font-size: 50px;
  margin-top: 16px;
}
@media screen and (max-width: 834px) {
  .userProfile_box_header_datos h2 {
    font-size: 36px;
    margin-bottom: 32px;
  }
}
.userProfile_box_header_btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.userProfile_box_header_btn button {
  width: 131px;
  background-color: #FF9900;
  color: white;
  font-size: 14px;
  font-weight: bold;
  height: 42px;
  border-radius: 8px;
  text-transform: uppercase;
  box-sizing: border-box;
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.userProfile_box_header_btn button:hover {
  -webkit-filter: brightness(70%);
}
.userProfile_box_header_btn button img {
  width: 30px;
}

.invernada {
  margin: auto;
  margin-top: 100px;
  padding: 32px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .invernada {
    padding: 32px 0;
  }
}
@media screen and (max-width: 834px) {
  .invernada {
    margin-top: 0;
  }
}
.invernada_layout {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 32px;
  gap: 32px;
  justify-content: center;
}
@media (min-width: 20em) and (max-width: 68.74em) {
  .invernada_layout {
    flex-direction: column;
  }
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .invernada_layout {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1080px) {
  .invernada_layout {
    flex-wrap: nowrap;
  }
}
.invernada_layout_filters {
  background-color: #ddd;
  background-color: white;
  width: 100%;
  top: 118px;
  position: fixed;
  z-index: 3;
  border: 1px solid #ddd;
  border-width: 0 0 1px 0;
  left: 0;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .invernada_layout_filters {
    top: 120px;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .invernada_layout_filters {
    top: 118px;
  }
}
.invernada_layout_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.invernada_card {
  width: 100%;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 32px 54px 500px 1fr;
  grid-template-rows: 32px 1fr 100px;
  grid-template-areas: "info orden imagen timer" "info orden imagen data" "info orden imagen btns";
}
.invernada_card_principal {
  border: 1px solid lightgrey;
  border-width: 0px 0px 1px 0px;
  display: flex;
  padding: 16px;
  justify-content: space-between;
}
@media screen and (max-width: 834px) {
  .invernada_card_principal {
    flex-direction: column-reverse;
    grid-gap: 32px;
    gap: 32px;
  }
}
.invernada_card_principal_label {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 12px;
}
.invernada_card_principal_value {
  font-weight: bold;
  font-size: 14px;
  color: #66A38C;
}
.invernada_card_secundaria {
  padding: 16px;
  display: flex;
  border: 1px solid lightgrey;
  border-width: 0px 0px 1px 0px;
  grid-gap: 16px;
  gap: 16px;
  flex-wrap: wrap;
}
.invernada_card_secundaria_bloque {
  width: 200px;
}
.invernada_card_description {
  padding: 16px;
}
.invernada_card_info {
  grid-area: info;
  background-color: #19513C;
  color: white;
  font-size: 14px;
  text-align: center;
  width: 100%;
  height: 100%;
}
.invernada_card_info p {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  width: 300px;
}
.invernada_card_order {
  grid-area: orden;
}
.invernada_card_img {
  grid-area: imagen;
}
.invernada_card_timer {
  grid-area: timer;
}
.invernada_card_data {
  grid-area: data;
}
.invernada_card_btns {
  grid-area: btns;
}

.buttonView {
  padding: 10px 15px;
  border-radius: 5px;
  background: grey;
  color: white !important;
  margin-right: 10px;
  cursor: pointer;
}

.button-list-view-wrapper {
  display: flex;
  flex: 100% 1;
  justify-content: center;
}

.invernada2 {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 32px;
  border-radius: 16px;
  overflow: hidden;
  background-color: white;
  width: 100%;
  max-width: 1200px;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 32px 64px 400px auto;
  grid-template-rows: 32px auto 80px;
  grid-template-areas: "status named img timer" "status named img body" "status named img btns";
}
@media screen and (max-width: 1080px) {
  .invernada2 {
    grid-template-columns: 1fr;
    grid-template-rows: 32px 32px 400px 32px auto 80px;
    grid-template-areas: "status" "named" "img" "timer" "body" "btns";
  }
}
.invernada2_status {
  background: linear-gradient(-45deg, grey, lightgrey);
  background-size: 400% 400%;
  -webkit-animation: gradient 0.5s ease infinite;
          animation: gradient 0.5s ease infinite;
  grid-area: status;
}
@media screen and (max-width: 1080px) {
  .invernada2_status div p {
    -webkit-transform: none;
            transform: none;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
  }
}
.invernada2_name {
  background-color: white;
  grid-area: named;
  position: relative;
}
.invernada2_name p {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin: auto;
  position: absolute;
  bottom: calc(50% + 50px);
  left: -68px;
  width: 200px;
}
@media screen and (max-width: 1080px) {
  .invernada2_name p {
    -webkit-transform: none;
            transform: none;
    text-align: center;
    width: 100%;
    left: 0px !important;
    top: 6px;
  }
}
.invernada2_image {
  background-color: #ddd;
  grid-area: img;
}
.invernada2_image img {
  width: 100%;
  object-fit: contain;
}
.invernada2_timer {
  background-color: #66A38C;
  grid-area: timer;
  color: white;
  text-align: center;
}
.invernada2_body {
  background-color: white;
  grid-area: body;
}
.invernada2_btns {
  background-color: white;
  grid-area: btns;
}

.filter {
  padding: 22px 48px;
  display: flex;
  grid-gap: 48px;
  gap: 48px;
  grid-row-gap: 12px;
  row-gap: 12px;
  justify-content: center;
  align-items: center;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .filter {
    flex-wrap: wrap;
  }
}
.filter_input {
  width: 200px !important;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .filter_input {
    width: 100% !important;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .filter_input {
    width: 130px !important;
  }
}
.filter_group {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .filter_group {
    flex-direction: column;
    width: 100%;
  }
}
.filter_submit {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}
@media screen and (max-width: 834px) {
  .filter_submit {
    width: 100vw;
  }
}
.filter_openbtn {
  text-align: center;
  height: 40px;
  align-items: center;
  display: none;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .filter_openbtn {
    display: block;
  }
}
.filter_icon {
  width: 40px;
  color: red;
}

.btn_filter {
  margin-left: 0;
  padding-top: 0 !important;
}
@media screen and (max-width: 834px) {
  .btn_filter {
    width: 100% !important;
  }
}

.filterHide {
  margin-top: 0px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .filterHide {
    top: -200px;
  }
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 75%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 75%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.viewActive {
  background: #19513c !important;
}

.status-active {
  background: #66a38c !important;
  color: white !important;
  font-weight: 600;
}

.status-finished {
  background: #f96161 !important;
  color: white !important;
  font-weight: 600;
}

.status-initial {
  background: #bbbbbb !important;
  color: white !important;
  font-weight: 600;
}

.lote-props-mobile-wrapper {
  padding: 0 20px 0;
}

.lote-props-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 20px 0;
}
@media (min-width: 20em) and (max-width: 68.74em) {
  .lote-props-wrapper {
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 5%;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-top: 2rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .lote-props-wrapper {
    justify-content: space-between;
    margin-top: 1rem;
  }
}

.lote-prop-mobile-obs-block {
  margin-bottom: 1.5rem;
}

.lote-prop-mobile-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
  grid-gap: 18px;
}

@media (min-width: 68.75em) and (max-width: 99.99em) {
  .lote-prop-block {
    flex: 0 0 25%;
  }
}
@media (min-width: 100em) {
  .lote-prop-block {
    flex: 0 0 25%;
  }
}

.lotes-props-invernada {
  display: block;
}

.property-mobile-invernada-flex {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  font-size: 13px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .property-mobile-invernada-flex > p {
    font-size: 13px;
  }
}
.property-mobile-invernada-flex > p:nth-of-type(1) {
  font-weight: 700;
}

.property-invernada-flex {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  margin-bottom: 10px;
  font-size: 13px;
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .property-invernada-flex {
    font-size: 12px;
    grid-gap: 0;
    gap: 0;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .property-invernada-flex {
    font-size: 12px;
    grid-gap: 0;
    gap: 0;
  }
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .property-invernada-flex > p {
    font-size: 13px;
  }
}
.property-invernada-flex > p:nth-of-type(1) {
  font-weight: 700;
}

.picture-box {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.picture-box-front {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: absolute;
  height: 100%;
}
.picture-box-front img {
  height: 100%;
  width: 100%;
}
.picture-box-back {
  position: absolute;
  top: 0;
  z-index: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: right;
}
.picture-box-back img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-filter: blur(20px);
          filter: blur(20px);
}

.lotesSmall {
  background-color: white;
  width: 100vw;
  max-width: 1000px;
  margin-bottom: 8px;
  min-height: 90px;
  border-radius: 16px;
  box-sizing: border-box;
}

.consumoContainer {
  background-color: white;
}

.consumoContent {
  padding-bottom: 5rem;
}
@media (min-width: 46.25em) {
  .consumoContent {
    width: 90%;
    margin: 0 auto;
  }
}

.noTextDecoration {
  text-decoration: none !important;
}

.image-lote-mobile {
  width: 100%;
}

@media (min-width: 20em) and (max-width: 46.24em) {
  .mobileSpacing {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.consumoDataContent {
  margin: 3rem 0;
}
.consumoDataContent > h2 {
  font-weight: 700;
  text-align: center;
  color: #19513C;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .consumoDataContent > h2 {
    font-size: 20px;
  }
}
@media (min-width: 46.25em) {
  .consumoDataContent > h2 {
    font-size: 25px;
  }
}
.consumoDataContent > h3 {
  font-weight: 500;
  text-align: center;
  color: #19513C;
  text-decoration: underline;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .consumoDataContent > h3 {
    font-size: 20px;
  }
}
@media (min-width: 46.25em) {
  .consumoDataContent > h3 {
    font-size: 22px;
  }
}
.consumoDataContent > h4 {
  font-weight: 500;
  text-align: center;
  color: #19513C;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .consumoDataContent > h4 {
    font-size: 14px;
  }
}
@media (min-width: 46.25em) {
  .consumoDataContent > h4 {
    font-size: 17px;
  }
}

.consumoTitleContent {
  margin: 0 0 3rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.consumoTitle {
  text-align: center;
  color: #19513C;
  font-weight: 700;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .consumoTitle {
    font-size: 20px;
    margin-top: 2rem;
    margin-bottom: 0.6rem;
  }
}
@media (min-width: 46.25em) {
  .consumoTitle {
    font-size: 55px;
    margin-top: 5rem;
    margin-bottom: 1rem;
  }
}

.consumoTitleLink {
  text-align: center;
  font-weight: 600;
  color: #19513C;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .consumoTitleLink {
    font-size: 14px;
  }
}
@media (min-width: 46.25em) {
  .consumoTitleLink {
    font-size: 25px;
  }
}

.consumoButtonsTop {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  border-bottom: 1px solid grey;
}
.consumoButtonsTop .active {
  background-color: #19513C;
  color: white !important;
}
@media (min-width: 46.25em) {
  .consumoButtonsTop {
    width: 40rem;
    margin: 0 auto;
  }
}
.consumoButtonsTop > a {
  flex: 1 1;
  text-align: center;
  display: flex;
  font-weight: 600;
  color: #19513C;
  align-items: center;
  justify-content: center;
  border-top: 1px solid green;
  border-left: 1px solid green;
  border-right: 1px solid green;
  border-radius: 5px 5px 0 0;
  text-decoration: none;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .consumoButtonsTop > a {
    font-size: 12px;
    padding: 0.5rem;
  }
}
@media (min-width: 46.25em) {
  .consumoButtonsTop > a {
    font-size: 1rem;
    padding: 1rem;
  }
}

.contentDataTableContainer {
  margin-top: 1.2rem;
}

.consumoMobileContainer {
  width: 100%;
}
.consumoMobileContainer > div:nth-child(even) {
  background-color: #F3F8F6;
}

.consumoMobileWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 2rem;
}

.consumoMobileContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d8d7d7;
  padding: 10px 0;
}
.consumoMobileContent > p {
  color: #19513C;
  font-size: 0.9rem;
}
.consumoMobileContent > p:first-child {
  margin-left: 6%;
  font-weight: 700;
}
.consumoMobileContent > p:last-child {
  margin-right: 6%;
}

.customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customers td, .customers th {
  padding: 15px 8px;
  color: #19513C;
}

.customers td {
  border: none;
}

.customers th {
  border-bottom: 1px solid #ddd;
}

.backgroundTransparent {
  background-color: transparent !important;
}

.customers tr:nth-child(odd) {
  background-color: #F3F8F6;
}

.customers tr:hover {
  background-color: #d3e5de;
}

.customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

.consumoPreciosYears {
  display: grid;
  justify-content: center;
  padding-bottom: 5rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .consumoPreciosYears {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    margin-top: 2rem;
  }
}
@media (min-width: 46.25em) {
  .consumoPreciosYears {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem;
    width: 65rem;
    margin: 3rem auto;
  }
}

.buttonYear {
  color: white;
  background: #66A38C;
  font-weight: 700;
  text-align: center;
  display: block;
  border-radius: 5px;
  text-decoration: none;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .buttonYear {
    font-size: 20px;
    padding: 1rem 0;
  }
}
@media (min-width: 46.25em) {
  .buttonYear {
    font-size: 35px;
    padding: 1.8rem 0;
  }
}
.buttonYear:hover {
  background: #477664;
}

.consumoEstadisticaYears {
  display: grid;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .consumoEstadisticaYears {
    grid-template-columns: 1fr;
    width: 90%;
    margin: 2rem auto;
  }
}
@media (min-width: 46.25em) {
  .consumoEstadisticaYears {
    grid-template-columns: 1fr 1fr 1fr;
    width: 70rem;
    grid-gap: 2rem;
    gap: 2rem;
    margin: 2rem auto;
  }
}

.consumoEstadisticasYear {
  padding: 10px 10px 20px 10px;
  color: white;
  background: #66A38C;
  border-radius: 5px;
  margin-bottom: 3rem;
}
.consumoEstadisticasYear > p {
  font-weight: 700;
  text-align: center;
  margin: 10px 0;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .consumoEstadisticasYear > p {
    font-size: 25px;
  }
}
@media (min-width: 46.25em) {
  .consumoEstadisticasYear > p {
    font-size: 35px;
  }
}

.consumoEstadisticaMonths {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 2rem 0;
  gap: 2rem 0;
}
.consumoEstadisticaMonths > a {
  text-decoration: none;
  font-weight: 500;
  flex: 0 1 50%;
  text-align: center;
}
.consumoEstadisticaMonths > a:hover {
  text-decoration: underline;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .consumoEstadisticaMonths > a {
    font-size: 20px;
  }
}
@media (min-width: 46.25em) {
  .consumoEstadisticaMonths > a {
    font-size: 25px;
  }
}

.consumoEstadisticasDatesWrapper {
  margin: 3rem 0;
}

.loginLiquidacionesWrapper {
  padding-bottom: 7rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .loginLiquidacionesWrapper {
    width: 90%;
    margin: 2rem auto;
  }
}
@media (min-width: 46.25em) {
  .loginLiquidacionesWrapper {
    width: 50rem;
    margin: 3rem auto;
  }
}

.inputLoginLiquidaciones {
  border: 1px solid #19513C;
  margin: 1rem auto;
  padding: 10px;
  font-size: 1.3rem;
  font-weight: 500;
  color: #19513C;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .inputLoginLiquidaciones {
    width: 90%;
  }
}
@media (min-width: 46.25em) {
  .inputLoginLiquidaciones {
    width: 50%;
  }
}

.buttonLoginLiquidaciones {
  background-color: #FF9900;
  margin: 1rem auto;
  color: white;
  font-weight: 600;
  padding: 1rem 5.5rem;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
}
.buttonLoginLiquidaciones:hover {
  background-color: #b36b00;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .buttonLoginLiquidaciones {
    font-size: 1rem;
  }
}
@media (min-width: 46.25em) {
  .buttonLoginLiquidaciones {
    font-size: 1.3rem;
  }
}

.consumoLiquidacionesInformesWrapper {
  display: grid;
  margin: 3rem auto;
  padding-bottom: 3rem;
  grid-gap: 1rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .consumoLiquidacionesInformesWrapper {
    grid-template-columns: 1fr;
    width: 90%;
  }
}
@media (min-width: 46.25em) {
  .consumoLiquidacionesInformesWrapper {
    grid-template-columns: 1fr 1fr;
    width: 60rem;
  }
}

.consumoLiquidacionesInformesButton {
  background-color: #66A38C;
  padding: 15px 20px;
  border-radius: 5px;
  text-decoration: none;
}
.consumoLiquidacionesInformesButton:hover {
  background-color: #477664;
}
.consumoLiquidacionesInformesButton > p {
  color: white;
  font-weight: 600;
  margin-bottom: 10px;
}

.informeHeader {
  background: #19513C;
  padding: 1rem 0;
  color: white;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  border-radius: 10px 10px 0 0;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .informeHeader {
    font-size: 1rem;
  }
}
@media (min-width: 46.25em) {
  .informeHeader {
    font-size: 1.6rem;
  }
}

.lotes-container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .lotes-container {
    width: 93%;
    margin: 2rem auto;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .lotes-container {
    width: 97%;
    margin: 2rem auto;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .lotes-container {
    width: 90%;
    margin: 2rem auto;
  }
}
@media (min-width: 100em) {
  .lotes-container {
    width: 80%;
    margin: 2rem auto;
  }
}

.lotes-invernada-container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .lotes-invernada-container {
    width: 93%;
    margin: 2rem auto;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .lotes-invernada-container {
    width: 97%;
    margin: 4rem auto;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .lotes-invernada-container {
    width: 90%;
    margin: 8rem auto;
  }
}
@media (min-width: 100em) {
  .lotes-invernada-container {
    width: 80%;
    margin: 8rem auto;
  }
}

.lote-wrapper {
  background-color: white;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .lote-wrapper {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 46.25em) {
  .lote-wrapper {
    margin-bottom: 2rem;
  }
}

.lote-status-bar {
  padding: 10px 15px 10px 10px;
  text-align: center;
  font-weight: 500;
  color: white;
  border-radius: 3px 3px 0 0;
  display: flex;
  align-items: center;
}
.lote-status-bar > p {
  text-transform: uppercase;
  font-weight: bold;
  flex: 1 1;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .lote-status-bar > p {
    font-size: 13px;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .lote-status-bar > p {
    font-size: 13px;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .lote-status-bar > p {
    font-size: 1.2rem;
  }
}
@media (min-width: 100em) {
  .lote-status-bar > p {
    font-size: 1.5rem;
  }
}

.lote-number-invernada-image a > img {
  display: block;
  border-radius: 0 0 0 3px;
  object-fit: cover;
  object-position: center;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .lote-number-invernada-image a > img {
    height: 185px;
    width: 100%;
    margin-bottom: 13px;
    border-radius: none;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .lote-number-invernada-image a > img {
    width: 205px;
    height: 131px;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .lote-number-invernada-image a > img {
    width: 330px;
    height: 165px;
    flex: 0 0 330px;
  }
}
@media (min-width: 100em) {
  .lote-number-invernada-image a > img {
    width: 360px;
    height: 140px;
    flex: 0 0 360px;
  }
}

.lote-number-image a > img {
  display: block;
  border-radius: 0 0 0 3px;
  object-fit: cover;
  object-position: center;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .lote-number-image a > img {
    height: 185px;
    width: 100%;
    margin-bottom: 13px;
    border-radius: none;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .lote-number-image a > img {
    width: 225px;
    height: 177px;
    flex: 0 0 225px;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .lote-number-image a > img {
    width: 330px;
    height: 185px;
    flex: 0 0 330px;
  }
}
@media (min-width: 100em) {
  .lote-number-image a > img {
    width: 360px;
    height: 200px;
    flex: 0 0 360px;
  }
}

.information-block-container {
  flex: 2 1;
}

.timer-lote-offer-wrapper {
  margin-top: 0;
}

.lote-your-offer {
  color: #FF9900;
  text-align: center;
  font-weight: 700;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin-top: 6px;
}
@media (min-width: 20em) and (max-width: 68.74em) {
  .lote-your-offer {
    font-size: 15px;
  }
}
.lote-your-offer > svg {
  margin-right: 5px;
}
@media (min-width: 20em) and (max-width: 68.74em) {
  .lote-your-offer > svg {
    width: 15px;
    height: 15px;
  }
}

.buy-type-block {
  margin-top: 15px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .buy-type-block {
    font-size: 12px;
    margin-top: 0;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .buy-type-block {
    font-size: 11px;
    margin-top: 0;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .buy-type-block {
    font-size: 12px;
    margin-top: 0;
  }
}
@media (min-width: 100em) {
  .buy-type-block {
    font-size: 13px;
    padding-left: 1rem;
  }
}

.block-blue {
  color: #4D79D3;
}

.block-green {
  color: #66A38C;
}

.lote-last-offer-wrapper {
  margin: 0.7rem 0 0;
}
.lote-last-offer-wrapper > p {
  font-weight: 700;
  padding: 0 1rem;
}
.lote-last-offer-wrapper > p:nth-of-type(1) {
  color: #545454;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .lote-last-offer-wrapper > p:nth-of-type(1) {
    font-size: 12px;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .lote-last-offer-wrapper > p:nth-of-type(1) {
    font-size: 12px;
    padding: 0;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .lote-last-offer-wrapper > p:nth-of-type(1) {
    padding: 0;
    font-size: 13px;
  }
}
@media (min-width: 100em) {
  .lote-last-offer-wrapper > p:nth-of-type(1) {
    font-size: 13px;
    padding: 0 1rem;
  }
}
.lote-last-offer-wrapper > p:nth-of-type(2) {
  color: #FF9900;
  font-size: 25px;
  font-weight: 700;
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .lote-last-offer-wrapper > p:nth-of-type(2) {
    font-size: 13px;
    padding: 0;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .lote-last-offer-wrapper > p:nth-of-type(2) {
    font-size: 20px;
    padding: 0;
  }
}
@media (min-width: 100em) {
  .lote-last-offer-wrapper > p:nth-of-type(2) {
    padding: 0 1rem;
  }
}

.green-text {
  color: #66A38C;
}

.lote-properties {
  margin-top: 15px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .lote-properties {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .lote-properties {
    margin-top: 0;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .lote-properties {
    margin-top: 0;
  }
}

.property-block {
  display: block;
  margin-bottom: 3px;
}
.property-block > p {
  font-weight: 700;
  word-break: break-word;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .property-block > p {
    font-size: 12px;
    margin-bottom: 7px;
  }
}
@media (min-width: 46.25em) and (max-width: 99.99em) {
  .property-block > p {
    font-size: 12px;
  }
}
@media (min-width: 100em) {
  .property-block > p {
    font-size: 13px;
  }
}
.property-block > p > span {
  font-weight: 500;
}

.property-flex {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  margin-bottom: 10px;
}
@media (min-width: 46.25em) and (max-width: 99.99em) {
  .property-flex {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
    margin-bottom: 5px;
    font-size: 12px;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .property-flex {
    font-size: 12px;
    margin-bottom: 5px;
    grid-gap: 0;
    grid-gap: 0;
    gap: 0;
  }
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .property-flex > p {
    font-size: 13px;
  }
}
.property-flex > p:nth-of-type(1) {
  font-weight: 700;
}

.lote-action-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .lote-action-buttons {
    grid-template-columns: 1fr;
    margin-bottom: 1rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .lote-action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 6px;
    gap: 6px;
    width: 100%;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .lote-action-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
  }
}
@media (min-width: 100em) {
  .lote-action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
    padding-top: 15px;
    grid-gap: 1rem;
    gap: 1rem;
  }
}

.btn-lote {
  text-align: center;
  padding: 0.6rem 0;
  color: white;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .btn-lote {
    font-size: 13px;
    font-weight: 600;
  }
}
@media (min-width: 46.25em) and (max-width: 99.99em) {
  .btn-lote {
    width: 80%;
    font-size: 11px;
  }
}
@media (min-width: 100em) {
  .btn-lote {
    width: 55%;
  }
}

.btn-lote-show-more {
  color: #19513C;
  text-decoration: underline;
  text-transform: uppercase;
}
.btn-lote-show-more:hover {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
@media (min-width: 46.25em) and (max-width: 99.99em) {
  .btn-lote-show-more {
    font-size: 10px;
    padding-top: 10px;
  }
}
@media (min-width: 100em) {
  .btn-lote-show-more {
    font-size: 14px;
  }
}

.btn-lote-offer {
  background-color: #FF9900;
}
.btn-lote-offer:hover {
  background-color: #cc7a00;
}

.btn-lote-disabled {
  background-color: lightgrey;
  pointer-events: none;
}

.no-padding {
  padding: 0 !important;
}

.timer-only-wrapper {
  color: #545454;
  font-weight: 600;
  text-align: left;
  flex: 1 1;
  font-size: 1.3rem;
  box-sizing: "border-box";
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .timer-only-wrapper {
    font-size: 18px;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .timer-only-wrapper {
    font-size: 15px;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .timer-only-wrapper {
    font-size: 1.1rem;
  }
}

.timer-mobile-wrapper {
  color: #545454;
  font-weight: 700;
  text-align: center;
  flex: 1 1;
  font-size: 1.3rem;
  box-sizing: "border-box";
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .timer-mobile-wrapper {
    font-size: 18px;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .timer-mobile-wrapper {
    font-size: 12px;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .timer-mobile-wrapper {
    font-size: 1.1rem;
  }
}

.lote-finished {
  color: #F96161;
}

.timer-wrapper {
  font-weight: 700;
  text-align: center;
  flex: 1 1;
  box-sizing: "border-box";
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .timer-wrapper {
    margin-left: 5%;
    color: #545454;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .timer-wrapper {
    font-size: 12px;
    color: #FFE3B8;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .timer-wrapper {
    font-size: 1.1rem;
    color: #FFE3B8;
  }
}
@media (min-width: 100em) {
  .timer-wrapper {
    font-size: 1.3rem;
    color: #FFE3B8;
  }
}

.tablet-desktop-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 1rem;
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .tablet-desktop-wrapper {
    grid-template-columns: 0.8fr 1fr 1fr;
    grid-gap: 5px;
    margin-bottom: 0;
    align-items: center;
  }
}

.lote-information-container {
  padding: 0 1rem;
  margin-top: 1rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .lote-information-container {
    padding: 0;
  }
}

[anchorfix=fixed]::before {
  content: "";
  display: block;
  height: 200px;
  margin-top: -200px;
  visibility: hidden;
}
@media screen and (max-width: 834px) {
  [anchorfix=fixed]::before {
    height: 150px;
    margin-top: -150px;
  }
}

* {
  scroll-behavior: smooth;
}

body {
  font-family: "DM Sans", sans-serif !important;
  overflow: scroll !important;
  overflow-x: hidden !important;
  padding-right: 0 !important;
  height: 100%;
}

main {
  min-height: calc(100vh - 238px);
  padding-top: 84px;
}
main ::-webkit-scrollbar {
  width: 10px;
}
main ::-webkit-scrollbar-track {
  border-radius: 10px;
}
main ::-webkit-scrollbar-thumb {
  background: #19513c;
  width: 10px;
  padding-top: 10px;
  border-radius: 10px;
}
main ::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

a {
  color: inherit;
}

img {
  text-indent: -10000px;
}

.green {
  color: green !important;
}

.atras {
  color: #19513C !important;
  text-align: center;
  padding: 30px 0;
  display: inline-block;
  font-size: 19px;
}

.align-center {
  text-align: center;
}

.m-1 {
  margin-top: 8px !important;
}

.big {
  font-size: 34px !important;
}

.orderer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1100px;
  margin: auto;
}

.extended {
  width: 100%;
}

.lottie-loading {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.lottie-loading-anim {
  width: 300px;
  margin: 100px auto 0;
}

.preloader > img {
  width: 150px;
  display: block;
  margin: auto;
}

.textoConsola {
  text-align: center;
  color: darkgrey;
  width: 100%;
  align-self: flex-end;
  height: 20px;
  font-size: 1.2rem;
}

.tag-yes {
  background-color: #FF9900;
  padding-top: 6px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  color: white;
  border-radius: 16px;
  width: 60px;
  height: 16px;
  text-align: center;
}

.tag-no {
  width: 90px;
  font-weight: bold;
  color: #FF9900;
  font-size: 14px !important;
}

.selected {
  border: 1px solid red;
}

.VideosMargin iframe {
  margin-bottom: 23px;
}

.lotesBigWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ordenRemates {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card1_container {
  max-width: 900px;
}

.cajasCheckbox {
  margin-top: 32px;
}

.checkbox {
  padding-left: 8px;
  width: 16px !important;
  box-sizing: border-box;
  height: 16px !important;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 8px;
}

.btn-disabled {
  background-color: lightgrey;
}

.btn-lottie-loading-anim {
  height: 100%;
  color: white;
}

.orderFixed {
  width: 100%;
}

.btn-pdf {
  font-size: 12px;
  padding: 8px 20px;
  color: white;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}
.btn-pdf:hover {
  background-color: #0d2a1f;
}

.activeReact {
  border: 1px solid #FF9900;
  border-width: 0 0 2px 0;
  padding-bottom: 5px;
  -webkit-animation-name: activeNavbar;
          animation-name: activeNavbar;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
}

@-webkit-keyframes activeNavbar {
  0% {
    border-width: 0 0 0 0;
  }
  100% {
    border-width: 0 0 2px 0;
  }
}

@keyframes activeNavbar {
  0% {
    border-width: 0 0 0 0;
  }
  100% {
    border-width: 0 0 2px 0;
  }
}
.logout_bye {
  background-color: #c4d4ce;
  border-radius: 50%;
  width: 300px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 50px;
}

.logout_bye_msj {
  width: 100%;
  text-align: center;
  margin-bottom: 100px;
  font-weight: bolder;
  color: #19513C;
}

.pastillaBtn {
  width: 144px !important;
}

.textoTimer {
  text-align: center;
  color: white;
  align-items: center;
  padding-top: 6px;
}

.recharts-wrapper {
  font-size: 12px !important;
}

.register_checkbox_label {
  margin-left: 8px;
  vertical-align: text-topw;
}

.alignItemsStrech {
  align-items: stretch;
  width: 100%;
}

.chartContainer > .recharts-wrapper {
  margin: 2rem auto;
}

.minOfferStyle {
  justify-content: flex-start !important;
  grid-gap: 6.5rem !important;
  gap: 6.5rem !important;
}

.iframeVideoContainer {
  position: relative;
  margin-bottom: 1.5rem;
  height: 0;
  overflow: hidden;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .iframeVideoContainer {
    padding-bottom: 56.2%;
  }
}
@media (min-width: 46.25em) {
  .iframeVideoContainer {
    padding-bottom: 56.2%;
  }
}

.iframeVideoContainer iframe,
.iframeVideoContainer object,
.iframeVideoContainer embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dialogBtn {
  color: white !important;
  padding: 7px 25px !important;
}

.dialogTitle {
  color: white;
  background: #19513c;
  margin-bottom: 2rem !important;
  padding: 10px 24px !important;
  text-align: center;
}

.dialogActionWrapper {
  justify-content: center !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cancelDialogButton {
  background-color: grey !important;
}

.submitDialogButton {
  background-color: #19513c !important;
}

.buttonIncrease {
  width: 50px;
  height: 50px;
  font-size: 1.6rem;
  border-radius: 3px;
  cursor: pointer;
  background: #FF9900;
  color: white;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}
.buttonIncrease:hover {
  background: #cc7a00;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .buttonIncrease {
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 100em) {
  .buttonIncrease {
    font-size: 43px;
    line-height: 45px;
  }
}

.offerNumberValue {
  font-weight: 500;
  color: #545454;
  font-size: 20px;
  padding-top: 10px;
  border: 1px solid #545454;
  padding: 11px 30px;
  border-radius: 3px;
  background: #EEEEEE;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .offerNumberValue {
    font-size: 13px;
    font-weight: 600;
  }
}

.align-left {
  text-align: left !important;
}

.no-padding {
  padding: 0 !important;
}

.no-border {
  border: 0 !important;
}

.dialogError {
  color: #ffffff;
  background: #620005;
  margin-bottom: 2rem !important;
  padding: 10px 24px !important;
  text-align: center;
}

.closeDialogError {
  background-color: #ba6f00 !important;
}

.customers-average {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  background-color: #fff3e0;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .customers-average {
    width: 100%;
  }
}
@media (min-width: 46.25em) {
  .customers-average {
    width: 35%;
  }
}

.customers-average td,
.customers-average th {
  padding: 15px 8px;
  color: #19513C;
}

.customers-average td {
  border: none;
}

.customers-average th {
  border-bottom: 1px solid #ddd;
}

.backgroundTransparent {
  background-color: transparent !important;
}

.customers-average tr:nth-child(odd) {
  background-color: #F3F8F6;
}

.customers-average tr:hover {
  background-color: #d3e5de;
}

.customers-average th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

@media (min-width: 20em) and (max-width: 46.24em) {
  .right-mobile {
    text-align: right !important;
  }
}

.bg-green-totals {
  background-color: #19513c !important;
}

.bg-pink-totals {
  margin-bottom: 2rem;
  background-color: #ffefef !important;
}
.bg-pink-totals > h2 {
  color: #19513C !important;
}
.bg-pink-totals > .consumoMobileContent > p {
  color: #19513C !important;
}

.amount-total-container > h2 {
  padding: 1rem 0;
  color: white;
  font-size: 1rem;
  text-align: center;
}
.amount-total-container > .consumoMobileContent > p {
  color: white;
}

.submenu {
  display: none !important;
  position: absolute;
  background-color: rgb(226, 225, 225);
  top: 50%;
  left: 0;
  margin-top: 0.5rem;
  min-width: 190px;
  z-index: 1;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .submenu {
    position: relative;
    top: 0;
    margin-top: 0;
    flex: 1 1 100%;
    padding-top: 2rem;
  }
}

.submenu-accordion {
  background-color: #19513c;
  flex: 1 1 100%;
  margin-bottom: 1rem;
}
.submenu-accordion > li {
  padding: 10px 0 !important;
  margin-bottom: 0 !important;
}
.submenu-accordion > li > a {
  padding: 10px 0 !important;
  display: block;
  text-decoration: none;
}

.menu-ul-list > li {
  position: relative;
  cursor: pointer;
}

.menu-ul-list > li:hover .submenu {
  display: block !important;
}

.submenu > li {
  margin: 0 !important;
}

.submenu > li > a {
  color: black;
  padding: 1rem;
  text-decoration: none;
  text-align: left !important;
  padding: 1rem;
  display: block;
}
.submenu > li > a:hover, .submenu > li > a:focus, .submenu > li > a:active {
  background-color: #19513C;
  color: white;
}

.offer-history-global-container {
  background-color: white;
  min-height: calc(100vh - 200px);
}

.offer-history-container {
  width: 70%;
  margin: auto;
  padding-top: 3rem;
  padding-bottom: 6rem;
  margin-top: 2rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .offer-history-container {
    width: 95%;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .offer-history-container {
    width: 90%;
  }
}
.offer-history-container > h1 {
  color: #19513C;
  font-size: 3.5rem;
  text-align: center;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .offer-history-container > h1 {
    font-size: 2rem;
  }
}
.offer-history-container > h2 {
  color: #19513C;
  font-size: 1.7rem;
  text-align: center;
  margin-top: 1rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .offer-history-container > h2 {
    font-size: 1.2rem;
  }
}

.list-container {
  margin-top: 4rem;
}

.list-block-container {
  margin-bottom: 4rem;
}
.list-block-container > h3 {
  background-color: #19513c;
  color: white;
  text-align: center;
  padding: 1rem 0;
  font-size: 1.2rem;
}

.list-inner-block-wrapper {
  padding: 2rem;
}

.top-block-offer-history {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.top-block-offer-history > p {
  flex: 0 1 85%;
  text-align: center;
  font-size: 1.3rem;
  /* padding: 1rem 0; */
  font-weight: 700;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .top-block-offer-history > p {
    flex: 0 1 70%;
    text-align: left;
    font-size: 1rem;
  }
}
.top-block-offer-history > a {
  flex: 0 1 15%;
  text-align: right;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .top-block-offer-history > a {
    flex: 0 1 30%;
    font-size: 1rem;
  }
}

.color-orange {
  color: #FF9900 !important;
}

.bottom-block-offer-history {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .bottom-block-offer-history {
    margin-top: 2rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .bottom-block-offer-history {
    margin-top: 2rem;
  }
}
.bottom-block-offer-history > div > p:nth-of-type(1) {
  font-size: 1rem;
  margin-bottom: 5px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .bottom-block-offer-history > div > p:nth-of-type(1) {
    font-size: 0.9rem;
  }
}
.bottom-block-offer-history > div > p:nth-of-type(2) {
  font-size: 1.4rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .bottom-block-offer-history > div > p:nth-of-type(2) {
    font-size: 0.8rem;
  }
}

.list-inner-container {
  background-color: white;
}
.list-inner-container > div:nth-child(even) {
  background-color: #f3f8f6;
}

.offer-history-table > tr > th {
  font-size: 1.5rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .offer-history-table > tr > th {
    font-size: 1rem;
  }
}
.offer-history-table > tr > td {
  font-size: 1.4rem;
  font-weight: 500;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .offer-history-table > tr > td {
    font-size: 1rem;
  }
}

@media (min-width: 20em) and (max-width: 46.24em) {
  .newsletter-footer-block {
    margin: 2rem 0;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .newsletter-footer-block {
    width: 50%;
  }
}
@media (min-width: 100em) {
  .newsletter-footer-block {
    width: 20%;
  }
}

.newsletter-text {
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
  margin-bottom: 19px;
  text-align: center;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .newsletter-text {
    font-size: 1.2rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .newsletter-text {
    font-size: 1rem;
  }
}

.newsletter-button {
  display: block;
  padding: 1rem;
  background-color: white;
  text-transform: uppercase;
  color: #19513c;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 8px;
  text-align: center;
  width: 50%;
  margin: 0 auto;
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .newsletter-button {
    padding: 0.7rem;
  }
}
.newsletter-button:hover, .newsletter-button:focus, .newsletter-button:active {
  background-color: #d9d9d9;
}

.general-container {
  margin-top: 37px;
}
@media (min-width: 20em) and (max-width: 99.99em) {
  .general-container {
    padding: 3rem 0 6rem;
    padding-top: 3rem;
    padding-bottom: 7rem;
  }
}
@media (min-width: 100em) {
  .general-container {
    width: 80%;
    margin: 37px auto 0;
    padding-top: 3rem;
    padding-bottom: 7rem;
  }
}

.general-banner-container {
  width: 100%;
  padding-bottom: 5rem;
}

.select-group-newsletter {
  margin-top: 1.3rem;
}
.select-group-newsletter > p {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.input-newsletter {
  margin-bottom: 1.3rem !important;
}

.button-submit {
  background-color: #19513C;
  color: white;
  border: none;
  padding: 0.7rem 2rem;
  font-size: 1.1rem;
  border-radius: 8px;
  margin-top: 3rem;
  cursor: pointer;
}
.button-submit:hover, .button-submit:focus, .button-submit:active {
  background-color: #071711;
  color: white;
}
.button-submit:disabled {
  background-color: #2b8b67;
}

.green-h1-title {
  text-align: center;
  color: #19513C;
  font-weight: 700;
  margin-bottom: 2rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .green-h1-title {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
}

.newsletter-container {
  background-color: white;
  border-radius: 15px;
  padding: 2rem 3rem;
  margin: 3rem auto 0;
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .newsletter-container {
    width: 70%;
  }
}
@media (min-width: 68.75em) {
  .newsletter-container {
    width: 50%;
  }
}

.button-back-wrapper {
  margin-top: 2rem;
  text-align: center;
}

.button-back {
  color: white;
  background-color: #19513C;
  padding: 10px 30px;
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  text-decoration: none;
  display: inline-block;
}

.d-inline-block {
  display: inline-block;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.text-decoration-none {
  text-decoration: none;
}

.closed-lote-text {
  box-sizing: border-box;
  color: white;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 0px;
  border-radius: 50px;
  width: 238px;
  background-color: rgb(249, 97, 97);
  font-size: 15px;
  font-weight: 600;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .closed-lote-text {
    margin-left: 5%;
  }
}

.pt-2 {
  padding-top: 2rem;
}

.button-share {
  background-color: #19513c;
  color: white;
  text-align: center;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 1rem;
}

@media (min-width: 20em) and (max-width: 46.24em) {
  .share-buttons-wrapper {
    padding-left: 0;
  }
}
.share-buttons-wrapper > button {
  margin-right: 10px;
}
.share-buttons-wrapper > button:last-of-type {
  margin-right: 0 !important;
}

.properties-container {
  background-color: white;
  border-radius: 8px;
  padding-bottom: 1.5rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .properties-container {
    width: 90%;
    margin: 2rem auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .properties-container {
    width: 75%;
    margin: 3rem auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 68.75em) {
  .properties-container {
    width: 75%;
    margin: 3rem auto;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.terms-container {
  background-color: white;
  border-radius: 8px;
  padding-bottom: 1.5rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .terms-container {
    width: 90%;
    margin: 0 auto;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .terms-container {
    width: 75%;
    margin: 0 auto;
  }
}
@media (min-width: 68.75em) {
  .terms-container {
    width: 75%;
    margin: 0 auto;
  }
}

@media (min-width: 20em) and (max-width: 46.24em) {
  .terms-block-of-text {
    padding: 0 1rem;
  }
}
@media (min-width: 46.25em) {
  .terms-block-of-text {
    padding: 0 3rem;
  }
}
.terms-block-of-text > p {
  margin-bottom: 20px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .terms-block-of-text > p {
    font-size: 0.8rem;
    color: #19513C;
    font-weight: 500;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .terms-block-of-text > p {
    font-size: 1rem;
    color: #19513C;
    font-weight: 500;
  }
}
@media (min-width: 68.75em) {
  .terms-block-of-text > p {
    font-size: 1rem;
    color: #19513C;
    font-weight: 500;
  }
}

.buttons-terms-wrapper {
  margin: 5rem auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.buttons-terms-wrapper > a {
  background-color: #19513c;
  color: white;
  text-decoration: none;
  padding: 1rem 1.5rem;
  text-align: center;
  font-weight: 600;
}

.block-divider {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.3rem;
  align-items: center;
  margin-bottom: 2rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .block-divider {
    display: flex;
    flex-direction: column;
  }
}
.block-divider > div > img {
  width: 100%;
  display: block;
}
.block-divider > div > p {
  font-size: 1.3rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .block-divider > div > p {
    font-size: 1rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .block-divider > div > p {
    font-size: 0.9rem;
  }
}
@media (min-width: 68.75em) {
  .block-divider > div > p {
    font-size: 1.3rem;
  }
}

@media (min-width: 20em) and (max-width: 46.24em) {
  .column-reverse {
    flex-direction: column-reverse;
  }
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.alert-dialog-description {
  font-size: 1.3rem !important;
  font-weight: 500 !important;
  text-align: center;
}

.chart-container {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.mt-1 {
  margin-top: 1rem;
}

.forms-container {
  margin: 37px auto 0;
  padding-bottom: 2rem;
}

.section-description {
  color: #19513C;
  font-weight: 500;
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 3rem;
}

@media (min-width: 20em) and (max-width: 46.24em) {
  .block-selects-wrapper {
    display: grid;
    grid-template-columns: 1fr !important;
    grid-gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
@media (min-width: 100em) {
  .block-selects-wrapper {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.buttons-home-categories {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  width: 55%;
  flex-wrap: wrap;
  margin: 0 auto;
}

.button-home {
  text-decoration: none;
  border-radius: 8px;
  background-color: white;
  text-align: center;
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 300px;
}
.button-home > img {
  display: block;
  width: 120px;
  margin: 0 auto;
}
.button-home > p {
  margin: 1rem 0;
  font-size: 1.3rem;
  color: #19513C;
  font-weight: 600;
}

.p-title-home {
  font-size: 2rem;
  color: #19513C;
  font-weight: 600;
  margin-bottom: 1.2rem;
  text-align: center;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .p-title-home {
    margin-top: 2rem;
    font-size: 1.4rem;
  }
}

.slider-home {
  padding: 3rem 0;
  background-color: white;
  margin-bottom: 5rem;
}

.img-logo-slider {
  padding: 20px;
  display: block;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .img-logo-slider {
    width: 300px;
    margin: 0 auto;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .img-logo-slider {
    width: 250px;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .img-logo-slider {
    width: 100%;
  }
}
@media (min-width: 100em) {
  .img-logo-slider {
    width: 280px;
  }
}

.folder-file-list-wrapper {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.folder-file-list-wrapper > h3 {
  color: #19513C;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}
.folder-file-list-wrapper > p {
  color: #19513C;
  font-weight: 500;
  font-size: 1rem;
}

.ul-file-list {
  list-style-type: none;
  margin-top: 2rem;
}
.ul-file-list > li {
  margin-bottom: 1rem;
}
.ul-file-list > li > a {
  font-size: 1.2rem;
  color: #19513C;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
}
.ul-file-list > li > a:hover, .ul-file-list > li > a:focus, .ul-file-list > li > a:active {
  text-decoration: none;
}
.ul-file-list > li > a > svg {
  font-size: 2rem;
  margin-right: 10px;
}

.content-minor-title {
  font-size: 1.2rem;
}

.normal-top-title {
  text-align: center;
  color: #19513C;
  font-weight: 700;
  font-size: 2rem;
  padding-top: 3rem;
  margin-bottom: 3rem;
}

.propiedades-rurales-information-block {
  position: relative;
  display: flex;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
@media (min-width: 20em) and (max-width: 68.74em) {
  .propiedades-rurales-information-block {
    display: block;
  }
}
.propiedades-rurales-information-block > p {
  font-size: 1.1rem;
  position: absolute;
  background: white;
  width: 30%;
  z-index: 1;
  margin-top: 8%;
  margin-left: 13%;
  padding: 1.5rem;
  border: 1px solid #EEEEEE;
  border-radius: 13px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .propiedades-rurales-information-block > p {
    width: 60%;
    position: relative;
    margin: 0 auto -60px;
    z-index: 1;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .propiedades-rurales-information-block > p {
    width: 370px;
    margin-bottom: -60px;
    z-index: 1;
    position: relative;
    margin: 0 auto -60px;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .propiedades-rurales-information-block > p {
    width: 370px;
    margin-left: 9%;
  }
}
@media (min-width: 100em) {
  .propiedades-rurales-information-block > p {
    width: 320px;
    margin-left: 15%;
  }
}
.propiedades-rurales-information-block > img {
  width: 70%;
  margin-left: auto;
  position: relative;
  border-radius: 12px;
}
@media (min-width: 20em) and (max-width: 68.74em) {
  .propiedades-rurales-information-block > img {
    width: 100%;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .propiedades-rurales-information-block > img {
    width: 75%;
  }
}

.block-selects-two-frags {
  grid-template-columns: 1fr 1fr;
}

.body-text-wrapper {
  padding: 0 3rem;
  margin-top: 2rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .body-text-wrapper {
    padding: 0;
  }
}

.body-text-title-block {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.body-text-title-block > img {
  display: block;
  width: 50px;
  margin-right: 10px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .body-text-title-block > img {
    width: 30px;
    margin-right: 5px;
  }
}
.body-text-title-block > h2 {
  color: #19513C;
  font-weight: 700;
}
@media (min-width: 20em) and (max-width: 68.74em) {
  .body-text-title-block > h2 {
    font-size: 1.3rem;
  }
}
@media (min-width: 68.75em) {
  .body-text-title-block > h2 {
    font-size: 2.5rem;
  }
}

.ul-list-text {
  margin-top: 1rem;
  margin-bottom: 3rem;
  margin-left: 1rem;
}
.ul-list-text > li {
  color: #19513C;
  font-size: 1rem;
  margin-bottom: 0.7rem;
  font-weight: 500;
}
.ul-list-text > li > ul {
  margin-left: 1rem;
}

.body-text-p-normal {
  color: #19513C;
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.body-text-p-title {
  color: #19513C;
  font-size: 1rem;
  margin-bottom: 5px;
  font-weight: 700;
}

.inst-banner {
  width: 100%;
  display: block;
}

.bg-white {
  background-color: white;
}

.full-container {
  margin-top: 34px;
}

.middle-content-wrapper {
  width: 80%;
  margin: 4rem auto 0;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .middle-content-wrapper {
    width: 90%;
  }
}
@media (min-width: 46.25em) {
  .middle-content-wrapper {
    width: 80%;
  }
}

.inst-block-divider {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.3rem;
  align-items: center;
  margin-bottom: 6rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .inst-block-divider {
    display: flex;
    flex-direction: column;
  }
}
.inst-block-divider > div > img {
  width: 80%;
  display: block;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .inst-block-divider > div > img {
    width: 90%;
    margin: 0 auto;
  }
}
.inst-block-divider > div > h2 {
  color: #19513C;
  font-weight: 700;
}
@media (min-width: 20em) and (max-width: 68.74em) {
  .inst-block-divider > div > h2 {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .inst-block-divider > div > h2 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }
}
@media (min-width: 100em) {
  .inst-block-divider > div > h2 {
    font-size: 3rem;
    margin-bottom: 3rem;
  }
}
.inst-block-divider > div > p {
  font-size: 1.3rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .inst-block-divider > div > p {
    font-size: 1rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .inst-block-divider > div > p {
    font-size: 0.9rem;
  }
}
@media (min-width: 68.75em) {
  .inst-block-divider > div > p {
    font-size: 1.3rem;
  }
}

.inst-titles {
  color: #19513C;
  margin-bottom: 3rem;
}
@media (min-width: 20em) and (max-width: 68.74em) {
  .inst-titles {
    font-size: 2rem;
  }
}
@media (min-width: 68.75em) {
  .inst-titles {
    font-size: 3rem;
  }
}

.inst-grid-elements {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin-bottom: 10rem;
}
@media (min-width: 20em) {
  .inst-grid-elements {
    grid-template-columns: 1fr;
    grid-gap: 2.5rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .inst-grid-elements {
    grid-template-columns: 1fr 1fr;
    grid-gap: 4.5rem;
  }
}
@media (min-width: 68.75em) {
  .inst-grid-elements {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4.5rem;
  }
}

.inst-element {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.inst-element > img {
  width: 55px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .inst-element > img {
    width: 45px;
  }
}
.inst-element > span {
  color: #545454;
  font-weight: 700;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .inst-element > span {
    font-size: 18px;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .inst-element > span {
    font-size: 20px;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .inst-element > span {
    font-size: 20px;
  }
}
@media (min-width: 100em) {
  .inst-element > span {
    font-size: 20px;
  }
}

.full-bg-image-wrapper {
  background-image: url(/static/media/inst_04.bb16c269.png);
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 8rem 0;
  margin-bottom: 6rem;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .full-bg-image-wrapper {
    padding: 6rem 0;
  }
}
.full-bg-image-wrapper > div > h2 {
  color: white;
  text-align: center;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .full-bg-image-wrapper > div > h2 {
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .full-bg-image-wrapper > div > h2 {
    font-size: 2.3rem;
    margin-bottom: 2rem;
  }
}
@media (min-width: 68.75em) {
  .full-bg-image-wrapper > div > h2 {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
}
.full-bg-image-wrapper > div > p {
  color: white;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 400;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .full-bg-image-wrapper > div > p {
    font-size: 1rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .full-bg-image-wrapper > div > p {
    font-size: 1rem;
  }
}
@media (min-width: 68.75em) {
  .full-bg-image-wrapper > div > p {
    font-size: 1.25rem;
  }
}

@media (min-width: 20em) and (max-width: 46.24em) {
  .centered-text {
    width: 90%;
    margin: 0 auto;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .centered-text {
    width: 65%;
    margin: 0 auto;
  }
}
@media (min-width: 68.75em) {
  .centered-text {
    width: 60%;
    margin: 0 auto;
  }
}

.values-wrapper {
  display: grid;
  grid-gap: 3rem;
  width: 50%;
  margin: 0 auto 6rem;
  align-items: center;
}
.values-wrapper > p {
  color: #545454;
  padding: 2.5rem 1rem;
  font-weight: 700;
  text-align: center;
  background-color: #F3F8F6;
  border-radius: 8px;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .values-wrapper > p {
    padding: 1rem;
    font-size: 1rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .values-wrapper > p {
    font-size: 1rem;
  }
}
@media (min-width: 68.75em) {
  .values-wrapper > p {
    font-size: 1.3rem;
  }
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .values-wrapper {
    grid-template-columns: 1fr;
    width: 90%;
    margin: 2rem auto 0;
    padding-bottom: 10rem;
    grid-gap: 1rem;
  }
}
@media (min-width: 46.25em) {
  .values-wrapper {
    grid-template-columns: 1fr 1fr;
    width: 50%;
    margin: 5rem auto 0;
    padding-bottom: 10rem;
  }
}

.text-center {
  text-align: center;
}

@media (min-width: 46.25em) and (max-width: 68.74em) {
  .flexible-width {
    width: 80%;
  }
}
@media (min-width: 68.75em) {
  .flexible-width {
    width: 80%;
  }
}

.ml-auto {
  margin-left: auto;
}

@media (min-width: 46.25em) and (max-width: 68.74em) {
  .ml-auto-block {
    width: 80%;
    margin-left: auto;
  }
}
@media (min-width: 68.75em) {
  .ml-auto-block {
    width: 80%;
    margin-left: auto;
  }
}

.no-padding {
  padding: 0 !important;
}

@media (min-width: 20em) and (max-width: 46.24em) {
  .button-tab {
    flex-shrink: 1 !important;
    font-size: 0.75rem !important;
  }
}

@media (min-width: 20em) and (max-width: 46.24em) {
  .box-total-wrapper {
    padding: 0 !important;
  }
}

@media (min-width: 20em) and (max-width: 46.24em) {
  .footer-info-wrapper {
    margin-top: 1rem;
  }
}

.pages-rep-slider {
  width: 100%;
}
.pages-rep-slider .swiper-slide img {
  display: block;
  width: 100%;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .pages-rep-slider {
    position: relative;
  }
}

.pages-slider .swiper-slide img {
  display: block;
  width: 100%;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .pages-slider {
    width: 100%;
    margin-bottom: 0;
    position: relative;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .pages-slider {
    width: 100%;
  }
}
@media (min-width: 68.75em) and (max-width: 99.99em) {
  .pages-slider {
    width: 100%;
  }
}
@media (min-width: 100em) {
  .pages-slider {
    height: 800px;
    width: 100%;
  }
}

.flex1 {
  flex: 1 1;
}

.align-right {
  text-align: right !important;
}

.global-accordion-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .global-accordion-container {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
}

.hr-separate {
  margin: 1.1rem 0;
  border: 1px solid rgba(240, 240, 240, 0.8);
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.p-share-text {
  font-size: 15px !important;
  font-weight: 600 !important;
  margin-bottom: 0;
  margin-top: 1.3rem;
  margin-bottom: 10px;
}

.active-lote {
  background-color: #66a38c;
}

.finished-lote {
  background-color: rgb(249, 97, 97);
}

.inactive-lote {
  background-color: rgb(189, 189, 189);
}

@media (min-width: 20em) and (max-width: 46.24em) {
  .box-form-wrapper {
    padding: 24px 5px !important;
  }
}

.puja-detail-wrapper {
  margin: 10px 0 0;
}
@media (min-width: 20em) and (max-width: 46.24em) {
  .puja-detail-wrapper > p {
    font-size: 13px;
  }
}
@media (min-width: 46.25em) {
  .puja-detail-wrapper > p {
    font-size: 14px;
  }
}

.inner-box-image-wrapper > img {
  height: 250px;
  object-fit: cover;
}

@media (min-width: 20em) and (max-width: 46.24em) {
  .banner-invernada {
    margin-top: 5rem;
  }
}
@media (min-width: 46.25em) and (max-width: 68.74em) {
  .banner-invernada {
    margin-top: 9rem;
  }
}
@media (min-width: 68.75em) {
  .banner-invernada {
    margin-top: 9rem;
  }
}

.invernada {
  margin-top: 0 !important;
}
