.consumoContainer {
	//margin-top: 37px;
	background-color: white;
}

.consumoContent {
	padding-bottom: 5rem;
	@include mq($from: tablet) {
		width: 90%;
		margin: 0 auto;
	}
}

.noTextDecoration {
	text-decoration: none !important;
}

.image-lote-mobile {
	width: 100%;
}

.mobileSpacing {
	@include mq(mobile, tablet) {
		padding-left: 5%;
		padding-right: 5%;
	}
}

.consumoDataContent {
	margin: 3rem 0;
	> h2 {
		font-weight: 700;
		text-align: center;
		color: $verdeOscuro;

		@include mq(mobile, tablet) {
			font-size: 20px;
			//margin-top: 3rem;
		}
		@include mq($from: tablet) {
			font-size: 25px;
			//margin-top: 5rem;
		}
	}
	> h3 {
		font-weight: 500;
		text-align: center;
		color: $verdeOscuro;
		text-decoration: underline;
		@include mq(mobile, tablet) {
			font-size: 20px;
		}
		@include mq($from: tablet) {
			font-size: 22px;
		}
	}
	> h4 {
		font-weight: 500;
		text-align: center;
		color: $verdeOscuro;
		@include mq(mobile, tablet) {
			font-size: 14px;
		}
		@include mq($from: tablet) {
			font-size: 17px;
		}
	}
}

.consumoTitleContent {
	margin: 0 0 3rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
}

.consumoTitle {
	text-align: center;
	color: $verdeOscuro;
	font-weight: 700;
	@include mq(mobile, tablet) {
		font-size: 20px;
		margin-top: 2rem;
		margin-bottom: 0.6rem;
	}
	@include mq($from: tablet) {
		font-size: 55px;
		margin-top: 5rem;
		margin-bottom: 1rem;
	}
}

.consumoTitleLink {
	text-align: center;
	font-weight: 600;
	color: $verdeOscuro;
	@include mq(mobile, tablet) {
		font-size: 14px;
	}
	@include mq($from: tablet) {
		font-size: 25px;
	}
}

.consumoButtonsTop {
	.active {
		background-color: $verdeOscuro;
		color: white !important;
	}
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1rem;
	border-bottom: 1px solid grey;

	@include mq($from: tablet) {
		width: 40rem;
		margin: 0 auto;
	}

	> a {
		flex: 1;
		text-align: center;
		display: flex;
		font-weight: 600;
		color: #19513C;
		align-items: center;
		justify-content: center;
		border-top: 1px solid green;
		border-left: 1px solid green;
		border-right: 1px solid green;
		border-radius: 5px 5px 0 0;
		text-decoration: none;

		@include mq(mobile, tablet) {
			font-size: 12px;
			padding: 0.5rem;
		}

		@include mq($from: tablet) {
			font-size: 1rem;
			padding: 1rem;
		}
	}
}

.contentDataTableContainer {
	margin-top: 1.2rem;
}

.consumoMobileContainer {
	width: 100%;
	> div:nth-child(even) {
		background-color: #F3F8F6;
	}
}

.consumoMobileWrapper {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	margin-bottom: 2rem;
}

.consumoMobileContent {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #d8d7d7;
	padding: 10px 0;
	> p {
		color: $verdeOscuro;
		font-size: 0.9rem;
	}
	> p:first-child {
		margin-left: 6%;
		font-weight: 700;
	}
	>p:last-child {
		margin-right: 6%;
	}
}

.customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customers td, .customers th {
  padding: 15px 8px;
	color: $verdeOscuro;
}

.customers td {
	border: none;
}

.customers th {
  border-bottom: 1px solid #ddd;
}

.backgroundTransparent {
	background-color: transparent !important;
}

.customers tr:nth-child(odd){background-color: #F3F8F6;}

.customers tr:hover {background-color: darken(#F3F8F6, 10%) }

.customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

.consumoPreciosYears {
	display: grid;
	justify-content: center;
	padding-bottom: 5rem;
	@include mq(mobile, tablet) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem;
		margin-top: 2rem;
	}
	@include mq($from: tablet) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 3rem;
		width: 65rem;
		margin: 3rem auto;
	}
}

.buttonYear {
	color: white;
	background: #66A38C;
	font-weight: 700;
	text-align: center;
	display: block;
	border-radius: 5px;
	text-decoration: none;

	@include mq(mobile, tablet) {
		font-size: 20px;
		padding: 1rem 0;
	}
	@include mq($from: tablet) {
		font-size: 35px;
		padding: 1.8rem 0;
	}
	&:hover {
		background: darken(#66A38C, 15%);
	}
}

.consumoEstadisticaYears {
	display: grid;
	@include mq(mobile, tablet) {
		grid-template-columns: 1fr;
		width: 90%;
		margin: 2rem auto;
	}
	@include mq($from: tablet) {
		grid-template-columns: 1fr 1fr 1fr;
		width: 70rem;
		gap: 2rem;
		margin: 2rem auto;
	}
}

.consumoEstadisticasYear {
	padding: 10px 10px 20px 10px;
	color: white;
	background: #66A38C;
	border-radius: 5px;
	margin-bottom: 3rem;
	> p {
		font-weight: 700;
		text-align: center;
		margin: 10px 0;
		@include mq(mobile, tablet) {
			font-size: 25px;
		}
		@include mq($from: tablet) {
			font-size: 35px;
		}
	}
}

.consumoEstadisticaMonths {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 2rem 0;

	> a {
		text-decoration: none;
		font-weight: 500;
		flex: 0 1 50%;
		text-align: center;
		&:hover {
			text-decoration: underline;
		}
		@include mq(mobile, tablet) {
			font-size: 20px;
		}
		@include mq($from: tablet) {
			font-size: 25px;
		}
	}
}
.consumoEstadisticasDatesWrapper {
	margin: 3rem 0;
}

.loginLiquidacionesWrapper {
	padding-bottom: 7rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	@include mq(mobile, tablet) {
		width: 90%;
		margin: 2rem auto;
	}
	@include mq($from: tablet) {
		width: 50rem;
		margin: 3rem auto;
	}
}

.inputLoginLiquidaciones {
	border: 1px solid $verdeOscuro;
	margin: 1rem auto;
	padding: 10px;
	font-size: 1.3rem;
	font-weight: 500;
	color: $verdeOscuro;
	@include mq(mobile, tablet) {
		width: 90%;
	}

	@include mq($from: tablet) {
		width: 50%;
	}
}

.buttonLoginLiquidaciones {
	background-color: #FF9900;
	margin: 1rem auto;
	color: white;
	font-weight: 600;
	padding: 1rem 5.5rem;
	text-decoration: none;
	cursor: pointer;
	border-radius: 5px;

	&:hover {
		background-color: darken(#FF9900, 15%);
	}
	@include mq(mobile, tablet) {
		font-size: 1rem;
	}

	@include mq($from: tablet) {
		font-size: 1.3rem;
	}
}

.consumoLiquidacionesInformesWrapper {
	display: grid;
	margin: 3rem auto;
	padding-bottom: 3rem;
	grid-gap: 1rem;
	@include mq(mobile, tablet) {
		grid-template-columns: 1fr;
		width: 90%;
	}
	@include mq($from: tablet) {
		grid-template-columns: 1fr 1fr;
		width: 60rem;
	}
}

.consumoLiquidacionesInformesButton {
	background-color: #66A38C;
	padding: 15px 20px;
	border-radius: 5px;
	text-decoration: none;
	&:hover {
		background-color: darken(#66A38C, 15%);
	}
	> p {
		color: white;
		font-weight: 600;
		margin-bottom: 10px;
	}
}

.informeHeader {
	background: $verdeOscuro;
	padding: 1rem 0;
	color: white;
	font-weight: 700;
	font-size: 1rem;
	text-align: center;
	border-radius: 10px 10px 0 0;
	@include mq(mobile, tablet) {
		font-size: 1rem;
	}
	@include mq($from: tablet) {
		font-size: 1.6rem;
	}
}
