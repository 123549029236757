.userProfile{
  &_botonera{
    padding-bottom: 89px;
    .btn{
      width: 100% !important;
      margin: auto;
      margin-bottom: 16px;
    }
  }
  &_container{
    padding-left: 128px;
    padding-right: 128px;
    @media screen and (max-width: $bp_ipad){
      padding-left: 28px;
      padding-right: 28px;
    }
  }
  &_box{
    max-width: 1472px;
    background-color: white;
    margin: auto;
    margin-top: 126px;
    border-radius: 16px;
    padding-left: 83px;
    padding-right: 83px;
    padding-top: 37px;
    padding-bottom: 83px;
    @media screen and (max-width: $bp_ipad){
      padding-left: 16px;
      padding-right: 16px;
    }
    &_contenido{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 85px;
      width: 100%;
      gap: 12px;
      @media screen and (max-width: $bp_ipad){
        flex-direction: column;
      }
      &_bloque{
        width: 48%;
        height: 80px;
        margin-bottom: 32px;
				word-break: break-all;
        @media screen and (max-width: $bp_ipad){
          width: 100%;
        }
        &_label{
          font-weight: bold;
          color: #545454;
          font-size: 18px;
          margin-bottom: 24px;
        }
        &_value{
          font-size: 14px;
          color: $naranja
        }
        &_input{
          width: 100%;
          height: 40px;
          border: 2px solid grey !important;
          padding: 8px;
          box-sizing: border-box;
          color: grey;
          outline-color: grey !important;
          &-red{
            border: 2px solid $statusRed !important;
          }
        }
      }
    }
    &_header{

      display: flex;
      justify-content: space-between;
      margin-bottom: 76px;
      overflow: hidden;
      @media screen and (max-width: $bp_ipad){
        flex-direction: column;
      }
      &_datos{
        &-conf{
          font-size: 16px;
        }
        &-user{
          font-size: 24px;
          font-weight: bold;
          color: $naranja;
        }
        h2{
          color: #545454;
          font-size: 50px;
          margin-top: 16px;
          @media screen and (max-width: $bp_ipad){
            font-size: 36px;
            margin-bottom: 32px;
          }
        }
      }
      &_btn{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        button{
          width: 131px;
          background-color: $naranja;
          color: white;
          font-size: 14px;
          font-weight: bold;
          height: 42px;
          border-radius: 8px;
          text-transform: uppercase;
          box-sizing: border-box;
          display: flex;
          padding: 16px;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          &:hover{
            -webkit-filter: brightness(70%);
          }
          img{
            width: 30px;
          }
        }

      }


    }
  }

}
