.lotesBig{
  width: 100%;
  padding: 8px 32px 8px 32px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: $bp_iphone){
    padding: 8px 16px 8px 16px;
  }
  &_portada{
    margin: auto;
    margin-top: 65px;
    width: calc(100% - 32px);
    max-width: 1000px;
    //overflow: hidden;
    border-radius: 16px;

    img{
      width: 100%;
      height: 100%;
      //max-width: 1000px;
      max-height: 550px;
      object-fit: contain;
      object-position: center center;
    }

		@include mq($from: tablet) {
			//height: 400px;
		}
  }

  &_card{
    background-color: white;
    max-width: 1000px;
    width: 100%;
    height: 100%;

    min-height: 250px;
    border-radius: 16px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 32px 64px 2fr 4fr;
    grid-template-rows: 25px 90px auto 1fr;
    grid-template-areas:
    "status order img timer"
    "status order img principal"
    "status order img secundaria"
    "status order img botonera";
    @media screen and (max-width: $bp_imac){
      grid-template-columns: 48px 60px 1fr;
      grid-template-rows: 32px 200px 120px auto auto;
      grid-template-areas:
      "status order timer"
      "status order img"
      "status order principal"
      "status order secundaria"
      "status order botonera";
    }
    @media screen and (max-width: $bp_ipad){
      grid-template-columns: 32px 32px 1fr;
      grid-template-rows: 32px 200px auto auto 90px;
    }
    &-multi{
      grid-template-columns: 48px 60px 1fr;
      grid-template-rows: 32px 300px 120px auto auto;
      grid-template-areas:
      "status order timer"
      "status order img"
      "status order principal"
      "status order secundaria"
      "status order botonera";
      @media screen and (max-width: $bp_ipad){
        grid-template-columns: 32px 32px 1fr;
        grid-template-rows: 32px 200px auto auto 110px;
      }
    }
    &_status{
      width: 100%;
      height: 100%;
      background-color: $statusGrey;
      grid-area: status;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-activo{
        background-color: $statusGreen;
      }
      &-terminado{
        background-color: $statusRed;
      }
      p{
        margin-bottom: -46px;
        transform: rotate(-90deg);
        color: white;
      }
    }
    &_timer{
      width: 100%;
      color: white;
      font-size: 14px;
      padding-top: 4px;
      grid-area: timer;
      text-align: center;
      &-activo{
        background-color: $statusGreen;
      }
      &-desactivado{
        background-color: $statusRed;
      }
    }
    &_order{
      background-color: white;
      grid-area: order;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 16px;
      border: 1px solid $grisClaro;
      border-width: 0 1px 0 0;
    }
    &_img{
      grid-area: img;
      background-color: #ddd;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &_infoPrincipal{
      position: relative;
      background-color: white;
      padding: 47px 32px 32px;
      display: flex;
      gap:8px;
      justify-content: space-between;
      grid-area: principal;
      border: 1px solid $grisClaro;
      border-width: 0 0 1px 0;
      @media screen and (max-width: $bp_iphone){
        flex-wrap: wrap;
        padding: 32px;
        gap: 35px;
        flex-direction: column-reverse;
      }
      &_bloques{
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 16px;
        @media screen and (max-width: $bp_ipad){
          width: 40%;
        }
        &_titulo{
          font-weight: bold;
          font-size: 12px;
          text-transform: uppercase;
          color: $negro;
        }
        &_descripcion{
          color: $statusGreen;
          margin-top: 12px;
          text-transform: capitalize;
        }
      }
      @media screen and (max-width: $bp_ipad){
        flex-wrap: wrap;
      }
    }
    &_infoSecundariaTable {
      background-color: white;
      grid-area: secundaria;
      min-height: 100px;
      padding-bottom: 8px;
      font-size: 12px;
      text-transform: uppercase;
      overflow-x: hidden;
      border: 1px solid #EEE;
      padding-bottom: 20px;
      margin-top: 10px;
      border-width: 0 0 1px 0;
      overflow: hidden;
      width: 100%;
      @media screen and (max-width: $bp_iphone){
        //overflow-x: scroll;
      }
      &-multi{
        height: 300px;
      }
      &_caja{
        &-multi{
          background-color: white;
          border-radius: 4px;
          flex-wrap: wrap;
          display: flex;
          justify-content: flex-start;
          row-gap:12px;
          column-gap: 8px;
          border: 1px solid lightgrey;
          border-width: 0 0 1px 0;
          padding-bottom: 16px;
        }
        &-multi-tabla {
          background-color: lightgrey;
          border-radius: 4px;
          flex-wrap: wrap;
          display: flex;
          justify-content: flex-start;
          row-gap:12px;
          column-gap: 8px;
          //border: 1px solid lightgrey;
          border-width: 0 0 1px 0;
          padding: 12px;
          margin-bottom: 10px;
        }
        background-color: white;
        border-radius: 4px;
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        @media screen and (max-width: $bp_iphone){
          flex-wrap: nowrap;
        }

        &::after{
          content: 'grrrrrrr';
          position: sticky;
          right: -40px;
          background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%);
          width: 50px;
          color: transparent;
          display: none;
          @media screen and (max-width: $bp_ipad){
            display: block;
          }
        }

        &_bloque{

          &_titulo-tabla {
            font-weight: bold;
            width: 100px;
            text-align: center;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-height: 35px;
          }

          &_titulo{
            font-weight: bold;
            width: 100px;
            margin-top: 16px;
            margin-bottom: 8px;
          }

          &_descripcion-tabla {
            width: 100px;
            margin-top: 8px;
            font-weight: 600;
            font-size: 12px;
            text-align: center;
            color: $statusGreen;
            @media screen and (max_width: $bp_iphone) {
              font-size: 13px;
            }
          }

          &_descripcion{
            width: 100px;
            margin-top: 8px;
            color: $statusGreen;
          }
        }
      }
    }

    &_infoSecundaria{
      background-color: white;
      grid-area: secundaria;
      min-height: 100px;
      padding: 32px;
      padding-top: 0;
      padding-bottom: 8px;
      font-size: 12px;
      text-transform: uppercase;
      overflow-x: hidden;
      border: 1px solid #EEE;
      padding-bottom: 20px;
      border-width: 0 0 1px 0;
      overflow: hidden;
      @media screen and (max-width: $bp_iphone){
        //overflow-x: scroll;
      }
      &-multi{
        height: 300px;
      }
      &_caja{
        &-multi{
          background-color: white;
          border-radius: 4px;
          flex-wrap: wrap;
          display: flex;
          justify-content: flex-start;
          row-gap:12px;
          column-gap: 8px;
          border: 1px solid lightgrey;
          border-width: 0 0 1px 0;
          padding-bottom: 16px;
        }
        &-multi-tabla {
          background-color: #f3f3f391;
          border-radius: 4px;
          flex-wrap: wrap;
          justify-content: flex-start;
          border-width: 0 0 1px 0;
          padding: 12px 0;
          margin-bottom: 10px;

					@include mq(mobile, tablet) {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;
						grid-gap: 1rem;
					}
					@include mq($from: tablet) {
						display: flex;
						row-gap:12px;
						column-gap: 8px;
					}
        }
        background-color: white;
        border-radius: 4px;
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        @media screen and (max-width: $bp_iphone){
          flex-wrap: nowrap;
        }

        &::after{
          content: 'grrrrrrr';
          position: sticky;
          right: -40px;
          background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%);
          width: 50px;
          color: transparent;
          display: none;
          @media screen and (max-width: $bp_ipad){
            display: block;
          }
        }

        &_bloque{

          &_titulo-tabla {
            font-weight: bold;
            width: 100px;
            text-align: center;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-height: 35px;
						@include mq(mobile, tablet) {
							font-size: 11px;
						}
						@include mq($from: tablet) {
							font-size: 12px;
						}
          }

          &_titulo{
            font-weight: bold;
            width: 100px;
            margin-top: 16px;
            margin-bottom: 8px;
          }

          &_descripcion-tabla {
            width: 100px;
            margin-top: 8px;
            font-weight: 600;
            font-size: 12px;
            text-align: center;
            color: $statusGreen;
						@include mq(mobile, tablet) {
							font-size: 12px;
						}
						@include mq($from: tablet) {
							font-size: 12px;
						}
          }

          &_descripcion{
            width: 100px;
            margin-top: 8px;
            color: $statusGreen;
          }
        }
      }

    }
    &_botonera{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 16px;
      padding-bottom: 16px;
      background-color: white;
      grid-area: botonera;
      &_multi{
        width: 100%;
        text-align: center;
        padding: 0 0 16px 0;
        cursor: pointer;
        text-decoration: underline;
        color: $naranja;
      }
    }
  }
}

.expand{
  height: 100% !important;
}

.numeracion{
  height: 200%;
}

.numer{
  color: blue;
  height: 100%;
  grid-area: numberOrder;
}

.btn-table {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid white;
  border-radius: 4px;
  text-align: center;
  color: white;
  padding: 8px 15px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 600;
  margin-left: 4px;
  margin-right: 4px;
  @media screen and (max-width: $bp_iphone) {
    font-size: 14px;
    padding: 10px 20px;
  }
}

.btn-table-viewMore {
  background-color: #19513C;
}

td.alignRightMobile {
  @media screen and (max-width: $bp_iphone) {
    text-align: right;
  }
}

.buyoutCaption {
  background-color: #4d79d3;
  color: white !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  min-height: 58px !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: $bp_iphone) {
    min-height: 30px !important;
    font-size: 13px !important;
  }
}

.divCaptionBuyout {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #4d79d3;
  padding: 10px 20px;
  color: white !important;
  font-size: 12px;
	font-weight: 700;
}

@media screen and (max-width: $bp_iphone) and (max-width: $bp_ipad) {
  .tableContainer {
    overflow: hidden;
  }
  .noContentTable:before {
    content: '' !important;
  }

  .status-initial, .status-active, .status-finished {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-weight: 600 !important;
    text-transform: uppercase;
  }

  .noPaddingMobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .tableLoteList {
    width: 100%;
  }

	.buyoutCaption {
		min-height: 65px !important;
	}

	.status-finished {
		font-size: 11px;
		padding: 0 40px !important;
	}
}

	/*
	table {
		display: block !important;
	}
	thead {
		display: block !important;
		tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
	}
	tbody {
		display: block !important;
	}
	th {
		display: block !important;
	}
	td {
		display: block !important;
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
    font-size: 13px !important;
		padding-left: 40% !important;

		&:before {
			position: absolute;
			top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
		}
		&:nth-of-type(1) {
			&:before {
				content: "Animales";
        text-align: left;
        padding-top: 15px;
        font-weight: 600;
			}
		}
		&:nth-of-type(2) {
			&:before {
				content: "";
			}
		}
		&:nth-of-type(3) {
			&:before {
				content: "Lote";
        text-align: left;
        padding-top: 5px;
        font-weight: 600;
			}
		}
		&:nth-of-type(4) {
			&:before {
				content: "Propietario";
        text-align: left;
        padding: 5px 0;
        font-weight: 600;
        font-size: 13px;
			}
		}
		&:nth-of-type(5) {
			&:before {
				content: "Descripción";
        text-align: left;
        padding: 5px 0;

        font-weight: 600;
        font-size: 13px;
			}
		}
		&:nth-of-type(6) {
			&:before {
				content: "Última oferta x Cuota x Animal";
        text-align: left;
        padding: 5px 0;
        font-weight: 600;
        font-size: 13px;

			}
		}
		&:nth-of-type(7) {
			&:before {
				content: "Ofertante";
        text-align: left;
        padding: 5px 0;
        font-weight: 600;
        font-size: 13px;
			}
		}
	}

	tr {
		display: block !important;
		margin: 0 0 1rem 0;
	}
}
*/
