.snackbar{
  pointer-events: none;
  z-index: 800;
  position: fixed;
  top: 114px;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  overflow: hidden;
  height: 110px;
  color: white;
  margin: auto;
  background-color: transparent !important;
  &_message{
    padding: 16px;
    text-align: center;
    border-radius: 8px;
    margin-top: -80px;
    background-color: $statusGreen
  }
  &-success{
      background-color: $statusGreen;
  }
  &-error{
      background-color: $statusRed;
  }
  &-hd{
    animation-name: hidde;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }
}
@keyframes hidde{
  0%{
    margin-top: -80px;
  }
  25%{
    margin-top: 0;
  }
  75%{
    margin-top: 0;
  }
  100%{
    margin-top: -80px;
  }
}
