.form{
  &_passwordReset{
    //margin-top: -40px;
    color: $verdeOscuro;
    text-align: center;
    width: 100% !important;
    font-size: 16px;
    span{
      color: $naranja;
      font-weight: bolder;
    }
  }
  &_flex{

    display: flex;
    gap: 34px;
    justify-content: space-between;
    @media screen and (max-width: $bp_ipad){
      flex-direction: column;
    }
  }

  &-m{
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 100px;
    margin-top: 75px;
    z-index: 1000;

    &-register{
      //margin-top: 150px;
    }
		@include mq(mobile, tablet) {
			margin-left: 3%;
			margin-right: 3%;
		}
  }

  form{
    padding: 16px;
    margin: auto 0;
  }

  &_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  box-sizing: border-box;
  color: grey;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  max-width: 1000px;
  border-radius: 8px;
  margin: auto;
  margin-top: 64px;
  margin-bottom: 30px;
  min-height: 400px;
  overflow: hidden;
  animation-name: fadeIn;
  animation-duration: .5s;
  animation-timing-function: ease-in;
  @media screen and (max-width: $bp_ipad){
    grid-template-columns: 1fr;
    grid-template-rows: 300px 1fr;
  }
  @media screen and (max-width: $bp_ipad){
    grid-template-rows: 200px 1fr;
  }
  h3{
    text-align: left;
    font-size: 32px;
    font-weight: lighter;
    color: $naranja;
  }
  .input_wide{
    width: 300px;
    @media screen and (max-width: $bp_ipad){
      width: 98%;
      margin-left: 0;
      max-width: 100%;

    }
  }
  input{
    padding-left: 8px;
    width: 100%;
    box-sizing: border-box;
    height: 32px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 8px;
    &:focus-visible{
        outline-color: $statusGreen;
      }
  }
  small{
    height: 12px;
    font-size: 12px;
    color: $statusRed;
    border: 1px solid white;
  }
  label{
    margin-top: 32px;
    font-weight: lighter;
    font-size: 14px;
  }
  &_box{
    margin-top: 32px;
    margin-bottom: 32px;
    &_item{
      margin-top: 8px;
    }
  }
  button{
    margin-top: 20px;
    margin-left: auto !important;
    padding-top: 0px;
    width: 100%;
		font-size: 16px;
  }
}

@keyframes fadeIn{
  from{
    margin-top: 84px;
    opacity: 0;
  }
  to{
    margin-top: 64px;
    opacity: 1;
  }
}
.Ghoast_input{
  pointer-events: none;
  width: 200px;
  height: 20px;
  position: relative;
  background-color: white;
  top: -26px;
  left: 8px;
  z-index: 20px;
}
