footer{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-top: 60px;
}

.footer{
  background-color: #19513C;
  box-sizing: border-box;
  width: 100%;
  padding: 36px;
  display: flex;
  padding-left: 64px;
  padding-right: 64px;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  font-size: 14px;

	@include mq(mobile, tablet) {
		flex-direction: column;
		padding-left: 30px;
		padding-right: 30px;
	}

	@include mq(tablet, desktop) {
		gap: 30px;
		padding: 30px;
	}

  &_title{
    margin-bottom: 16px;
    font-weight: bold;
  }
  &_text{
    color: #66A38C;
  }
  &_logo{
    display: flex;
    gap: 16px;
		@include mq(mobile, desktop) {
			justify-content: center;
		}
  }
  &_link{

    gap: 200px;
    line-height: 2;
    text-align: left;

    color: white;
    ul{
      color: white;
      list-style: none;
    }

  }
  &_icon{
    width: 12px;
    margin-right: 8px;
  }
}
.sub-footer{
  background-color: $statusGreen;
  height: 48px;
  color: white;
  font-weight: lighter;
  font-size: 12px;
  text-align: center;
  padding-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  img{
    height: 35px;
  }
}

.navbar_rrss-icon-png{
  height: 32px;
}
