.lightbox{
  overflow: scroll;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  &_btn{
    position: fixed;
    right: 40px;
    top: 90px;
    width: 48px;
    height: 48px;
    border-radius: 50%;

    cursor: pointer;
    z-index: 9999;
  }
  &_card{
    position: relative;
    width: calc(100% - 64px);
    height: 100%;
    max-width: 800px;
    margin: auto;
    margin-top: 100px;
    &_img{
      width: 100%;
      max-width: 800px;
      max-height: 700px;
      img{
        object-fit: cover;
        width: 100%;
        height: auto;
      }
    }
  }
}
.displayBlock{
  display: block !important;
}

.lb{
  display: none;
}
