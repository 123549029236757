.card1{
  padding: 32px;
  padding-top: 8px;
  padding-bottom: 0;
  margin-top: 32px;
  cursor: pointer;
	max-width: 900px;
	margin: auto;
	margin-bottom: 2rem;

  &_container{
    box-sizing: border-box;
    overflow: hidden;
    background-color: grey;
    width: 100%;
    max-width: 1200px;
    min-height: 300px;
    margin: auto;
    overflow: hidden;
    border-radius: 18px;
    display: grid;
    display: -webkit-grid;
    grid-template-columns: 400px 1fr;
    grid-template-rows: 32px 1fr;
    grid-template-areas:
      "img status"
      "img text";
    @media screen and (max-width: $bp_ipad){
      grid-template-columns: 100%;
      grid-template-rows: 32px 300px 250px;
      grid-template-areas:
        "status"
        "img"
        "text";
    }
    @media screen and (max-width: 630px){
      grid-template-columns: 100%;
      grid-template-rows: 32px 300px minmax(350px, auto);
      grid-template-areas:
        "status"
        "img"
        "text";
    }
    &_img{
      background-color: white;
      grid-area: img;
      display: flex;
      justify-content: center;
      background-color: #ddd;
      img{
        width: 100%;
        object-fit: cover;
        object-position: center center;
        @media screen and (max-width: $bp_ipad){
          height: 100%;
          width: auto;
        }
      }
    }
    &_status{
      color: white;
      background-color: #F96161;
      padding-left: 16px;
      padding-top: 8px;
      grid-area: status;
      font-size: 12px;
    }
    &_text{

      padding: 16px;
      padding-top: 32px;
      padding-bottom: 40px;
      background-color: white;
      grid-area: text;
      color: #545454;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      @media screen and (max-width: $bp_ipad){
        padding-top: 30px;
      }
      &_cajaSuperior{
        width: 100%;
        @media screen and (max-width: $bp_ipad){
          min-width: 100%;
        }
      }
      &_cajaInferior{
        align-self: flex-end;
      }
      &-cliente{
        font-size: 22px;
        width: 100%;
      }
      &-titulo{
        font-size: 28px;
        font-weight: 700;
        line-height: 100%;
        margin-top: 16px;
        margin-bottom: 32px;
        width: 100%;
        @media screen and (max-width: $bp_ipad){
          margin-top: 8px;
          margin-bottom: 32px;
        }
      }
      &-fecha{
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 8px;
        @media screen and (max-width: $bp_ipad){
          font-size: 16px;
        }
      }
      &-tiempo{
        font-size: 17px;
        color: $naranja;
      }
    }
  }
}

.statusGrey{
  background-color: $statusGrey;
}

.statusGreen{
  background-color: $statusGreen;
}

.statusRed{
  background-color: $statusRed;
}

.remates_list{
  margin-top: 50px;
  margin-bottom: 50px;
}
