.puja{
  &_status{
    &-able{
      background-color: $statusGreen;
      color: white;
      padding: 8px;
    }
    &-disable{
      background-color: $statusRed;
      color: white;
      padding: 8px;
    }
  }
}

.pujaForm{
  margin-bottom: 16px;
  &_container{

    max-width: 1000px;
    background-color: white;
    margin: auto;
    border-radius: 3px;
    //overflow: hidden;
  }
  &_secondBox{
    display: grid;
		grid-gap: 2rem;
		margin-top: 2rem;

		@include mq(mobile, tablet) {
			grid-template-columns: 1fr;
		}
		@include mq(tablet, desktop) {
			grid-template-columns: 1.4fr 1fr;
		}
		@include mq(desktop, wide) {
			grid-template-columns: 1fr 1fr;
		}
		@include mq(wide) {
			grid-template-columns: 1fr 1fr;
		}
  }

  &_name {
    box-sizing: border-box;
    text-transform: uppercase;
    color: white;
    text-align: center;
    font-size: 18px;
		font-weight: 500;
    padding: 15px 0;

		@include mq(mobile, tablet) {
			font-size: 15px;
			padding: 8px 0;
		}

		@include mq(tablet, desktop) {
			font-size: 22px;
		}

		@include mq(desktop, wide) {
			font-size: 23px;
		}
		@include mq(wide) {
			font-size: 23px;
		}
  }

  &_infobox {
    display: grid;
		gap: 2rem;
		@include mq(mobile, tablet) {
			grid-template-columns: 1fr;
			padding: 0;
			gap: 1rem;
		}
		@include mq(tablet, desktop) {
			grid-template-columns: 1.3fr 1fr;
			padding: 18px 18px 0;
		}
		@include mq($from: desktop) {
			grid-template-columns: 1fr 1fr;
			padding: 18px 18px 0;
		}
    &_img{
      position: relative;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
			@include mq(mobile, tablet) {
				width: 100%;
			}

			@include mq($from: tablet) {
				width: 100%;
				//height: 300px;
			}

      img{
        width: 100%;
        object-fit: contain;
        z-index: 2;
        height: 100%;
      }
    }
    &_text{
      padding: 0 18px 0;
      //@media screen and (max-width: $bp_ipad){
      //  width: 100%;
      //}
      p{
        font-size: 20px;
				font-weight: 500;
        margin-bottom: 5px;
      }
      h3{
				font-weight: 600;
				margin-bottom: 1.5rem;
				text-transform: uppercase;
				@include mq(mobile, tablet) {
					font-size: 24px;
					margin-bottom: 1rem;
				}
				@include mq(tablet, desktop) {
					font-size: 25px;
				}
				@include mq(desktop, wide) {
					font-size: 27px;
				}
				@include mq(wide) {
					font-size: 30px;
				}
      }
    }
    &_status{
      grid-area: status
    }
  }

  &_status-on {
    color: white;
    height: 32px;
    font-size: 14px;
    padding: 6px;
    box-sizing: border-box;
    text-align: center;
    background-color: $statusGreen;
  }
  &_status-off {
    color: white;
    height: 32px;
    font-size: 14px;
    padding: 6px;
    box-sizing: border-box;
    text-align: center;
    background-color: $statusGrey;
  }
  &_data{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
    font-size: 14px;

		@include mq(mobile, tablet) {
			padding: 0 20px;
		}

    &_incremento {
			& > p {
				font-weight: 700;
				margin-bottom: 5px;
			}
      & > h5{
        color: $naranja;
        font-size: 24px;
      }
    }
    &_oferta {
			& > p {
				font-weight: 700;
				margin-bottom: 5px;
			}
      & > h5{
        color: $naranja;
        font-size: 24px;
      }
    }

    &_buyout {
			& > p {
				font-weight: 700;
				margin-bottom: 5px;
			}
      & > h5 {
        color: #4d79d3;
        font-size: 24px;
      }
    }
    &_yourOffer{
      width: 160px;
      background-color: $naranja;
      color: white;
      height: 30px;
      text-align: center;
      font-size: 12px;
      padding: 8px;
      box-sizing: border-box;
      border-radius: 15px;
    }
    &_anotherOffer{
      background-color: $statusGreen;
      color: white;
      text-align: center;
      font-size: 15px;
      padding: 10px;
			width: 220px;
			justify-content: center;
      border-radius: 50px;
			display: inline-flex;
			margin: 1rem 0;
    }
  }
  &_input{
    padding: 20px;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 20px;

    label{
      display: block;
      font-size: 20px;
      margin-bottom: 10px;
			@include mq(mobile, tablet) {
				font-size: 20px;
				margin-bottom: 10px;
			}
    }
    input{
      width: 100%;
      padding: 8px;
      box-sizing: border-box;
      border-radius: 16px;
      border-width: 1px;
      border-style: none;
      border-color: grey !important;
      border: 1px solid red;
      padding-left: 16px;
      font-family: "DM Sans";
      color: grey;

    }
  }
  &_botonera{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 2rem;
		margin-top: 1rem;
		gap: 1.3rem;

		@include mq(mobile, tablet) {
			width: 90%;
			margin: 1rem auto 0;
		}
    &-buyout {
      height: 41px;
      background-color: #4d79d3;
      color: white;
      font-size: 15px;
      border-radius: 3px;
			font-weight: 700;
      cursor: pointer;
			transition: all 0.3s ease-in-out;

			&:hover {
				background-color: darken(#4d79d3, 10%);
			}

			@include mq(mobile, tablet) {
				width: 100%;
			}
			@include mq(tablet, desktop) {
				width: 80%;
			}
			@include mq(desktop, wide) {
				width: 80%;
			}
			@include mq(wide) {
				width: 80%;
			}
    }
    &-ofertar{
      height: 41px;
      background-color: $naranja;
      color: white;
      font-size: 15px;
			font-weight: 700;
      border-radius: 3px;
      cursor: pointer;
			transition: all 0.3s ease-in-out;

			&:hover {
				background-color: darken($naranja, 10%);
			}
			@include mq(mobile, tablet) {
				width: 100%;
			}
			@include mq(tablet, desktop) {
				width: 80%;
			}
			@include mq(desktop, wide) {
				width: 80%;
			}
			@include mq(wide) {
				width: 80%;
			}
    }
    &-update{
      text-align: center;
      background-color: $verdeOscuro;
      color: white;
      font-size: 15px;
      height: 41px;
			font-weight: 700;
      border-radius: 3px;
      cursor: pointer;
			transition: all 0.3s ease-in-out;

			@include mq(mobile, tablet) {
				width: 100%;
			}
			@include mq(tablet, desktop) {
				width: 80%;
			}
			@include mq(desktop, wide) {
				width: 80%;
			}
			@include mq(wide) {
				width: 80%;
			}
    }
  }
}
