.whatsapp{
   width: 60px;
   height: 60px;
   background-color: $statusGreen;
   position: fixed;
   bottom: 78px;
   right: 20px;
   border-radius: 50%;
   z-index: 999;
   :hover{
     transform: scale(0.9);
     opacity: 1;
     -webkit-filter: brightness(100%);
   }
   img{
     width: 100%;
     transform: scale(0.8);
     margin: auto;
   }
}
