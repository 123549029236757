.btn{
  cursor: pointer;
  display: inline-block;
  width: 144px;
  height: 40px;
  border: 1px solid white;
  border-radius: 4px;
  text-align: center;
  color: white;
  padding-top: 12px;
  box-sizing: border-box;
  font-size: 14px;
  margin-left: 4px;
  margin-right: 4px;
	transition: all 0.3s ease-in-out;
}
.btn-list{
  cursor: pointer;
  display: inline-block;
  width: 85px;
  border: 1px solid white;
  border-radius: 4px;
  text-align: center;
  color: white;
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 11px;
  margin-left: 4px;
  margin-right: 5px;
	text-decoration: none;
	display: block;
	margin-bottom: 5px;
}

.btn-invernada{
  margin-left: 0 !important;
  margin-right: 0 !important;
  @media screen and (max-width: 500px){
    width: 135px !important;
  }
}

.btn-invernada-list{
  margin-left: 0 !important;
  @media screen and (max-width: 500px){
    width: 135px !important;
  }
}

.invernada_hitbox{
  display: flex;
  gap: 8px;
}

.btn-long{
  width: 47%;
}

@media screen and (max-width: 500px){
  .btn{
    width: 94px;
  }
}
.btn:hover{
  -webkit-filter: brightness(70%);
}
.btn_cta{
  background-color: $naranja;
  border: none;
}
.btn_cta_list{
  margin-right: 0 !important;
  background-color: $naranja;
  border: none;
}
.btn_ctaBorder{
  background-color: none;
  border: 1px solid $naranja;
  color: $naranja;
}
.btn-ppl{
  background-color: #19513C;
  border: none;
}
.btn-ppl-list{
  background-color: #19513C;
  border: none;
}
.btn-disabled{
  cursor: not-allowed;
  background-color: $grisClaro;
}
.hone{
  display: none;
  @media screen and (max-width: $bp_ipad){
    display: none;
  }
}
