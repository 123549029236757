.picture{
  &-box{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    &-front{
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      position: absolute;
      height: 100%;
      img{
        height: 100%;
        width: 100%;
      }
    }
    &-back{
      position: absolute;
      top: 0;
      z-index: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: right;
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        filter: blur(20px);

      }
    }
  }
}
