.lotes-container {
	display: flex;
	flex-direction: column;

	@include mq(mobile,tablet) {
		width: 93%;
		margin: 2rem auto;
	}

	@include mq(tablet, desktop) {
		width: 97%;
		margin: 2rem auto;
	}

	@include mq(desktop, wide) {
		width: 90%;
		margin: 2rem auto;
	}

	@include mq(wide) {
		width: 80%;
		margin: 2rem auto;
	}
}

.lotes-invernada-container {
	display: flex;
	flex-direction: column;

	@include mq(mobile,tablet) {
		width: 93%;
		margin: 2rem auto;
	}

	@include mq(tablet, desktop) {
		width: 97%;
		margin: 4rem auto;
	}

	@include mq(desktop, wide) {
		width: 90%;
		margin: 8rem auto;
	}

	@include mq(wide) {
		width: 80%;
		margin: 8rem auto;
	}
}

.lote-wrapper {
	background-color: white;

	@include mq(mobile, tablet) {
		margin-bottom: 2rem;
		display: flex;
		flex-direction: column;
	}

	@include mq($from: tablet) {
		margin-bottom: 2rem;
	}
}

.lote-status-bar {
	padding: 10px 15px 10px 10px;
	text-align: center;
	font-weight: 500;
	color: white;
	border-radius: 3px 3px 0 0;
	display: flex;
	align-items: center;
	> p {
		text-transform: uppercase;
		font-weight: bold;
		flex: 1;
		@include mq(mobile, tablet) {
			font-size: 13px;
		}
		@include mq(tablet, desktop) {
			font-size: 13px;
		}
		@include mq(desktop, wide) {
			font-size: 1.2rem;
		}
		@include mq(wide) {
			font-size: 1.5rem;
		}
	}
}

.lote-number-invernada-image {
	a	> img {
		display: block;
		border-radius: 0 0 0 3px;
		object-fit: cover;
		object-position: center;

		@include mq(mobile, tablet) {
			height: 185px;
			width: 100%;
			margin-bottom: 13px;
			border-radius: none;
		}

		@include mq(tablet, desktop) {
			//width: 100%;
			width: 205px;
			height: 131px;
		}

		@include mq(desktop, wide) {
			width: 330px;
			height: 165px;
			flex: 0 0 330px;
		}

		@include mq(wide) {
			width: 360px;
			height: 140px;
			flex: 0 0 360px;
		}
	}
}
.lote-number-image {
	a > img {
		display: block;
		border-radius: 0 0 0 3px;
		object-fit: cover;
		object-position: center;

		@include mq(mobile, tablet) {
			height: 185px;
			width: 100%;
			margin-bottom: 13px;
			border-radius: none;
		}

		@include mq(tablet, desktop) {
			width: 225px;
			height: 177px;
			flex: 0 0 225px;
		}

		@include mq(desktop, wide) {
			width: 330px;
			height: 185px;
			flex: 0 0 330px;
		}

		@include mq(wide) {
			width: 360px;
			height: 200px;
			flex: 0 0 360px;
		}
	}
}

.information-block-container {
	flex: 2;
}

.timer-lote-offer-wrapper {
	margin-top: 0;
}

.lote-your-offer {
	color: #FF9900;
	text-align: center;
	font-weight: 700;
	font-size: 17px;
	display: flex;
	align-items: center;
	margin-top: 6px;

	@include mq(mobile, desktop) {
		font-size: 15px;
	}

	> svg {
		margin-right: 5px;
		@include mq(mobile, desktop) {
			width: 15px;
			height: 15px;
		}
	}
}

.buy-type-block {
	margin-top: 15px;
	font-weight: 700;
	text-transform: uppercase;

	@include mq(mobile, tablet) {
		font-size: 12px;
		margin-top: 0;
	}
	@include mq(tablet, desktop) {
		font-size: 11px;
		margin-top: 0;
	}

	@include mq(desktop, wide) {
		font-size: 12px;
		margin-top: 0;
	}

	@include mq(wide) {
		font-size: 13px;
		padding-left: 1rem;
	}
}

.block-blue {
	color: #4D79D3;
}

.block-green {
	color: #66A38C;
}

.lote-last-offer-wrapper {
	margin: 0.7rem 0 0;

	> p {
		font-weight: 700;
		padding: 0 1rem;

		&:nth-of-type(1) {
			color: #545454;
			@include mq(mobile, tablet) {
				font-size: 12px;
			}
			@include mq(tablet, desktop) {
				font-size: 12px;
				padding: 0;
			}
			@include mq(desktop, wide) {
				padding: 0;
				font-size: 13px;
			}
			@include mq(wide) {
				font-size: 13px;
				padding: 0 1rem;
			}
		}

		&:nth-of-type(2) {
			color: #FF9900;
			font-size: 25px;
			font-weight: 700;
			@include mq(tablet, desktop) {
				font-size: 13px;
				padding: 0;
			}
			@include mq(desktop, wide) {
				font-size: 20px;
				padding: 0;
			}
			@include mq(wide) {
				padding: 0 1rem;
			}
		}
	}
}

.green-text {
	color: #66A38C;
}

.lote-properties {
	margin-top: 15px;
	@include mq(mobile, tablet) {
		margin-left: 5%;
		margin-right: 5%;
		margin-bottom: 1rem;
	}
	@include mq(tablet, desktop) {
		margin-top: 0;
	}
	@include mq(desktop, wide) {
		margin-top: 0;
	}
}

.property-block {
	display: block;
	margin-bottom: 3px;

	> p {
		font-weight: 700;
		word-break: break-word;

		@include mq(mobile, tablet) {
			font-size: 12px;
			margin-bottom: 7px;
		}
		@include mq(tablet, wide) {
			font-size: 12px;
		}
		@include mq(wide) {
			font-size: 13px;
		}

		> span {
			font-weight: 500;
		}
	}
}

.property-flex {
	display: flex;
	gap: 5px;
	margin-bottom: 10px;

	@include mq(tablet, wide) {
		flex-direction: column;
		gap: 0;
		margin-bottom: 5px;
		font-size: 12px;
	}
	@include mq(desktop, wide) {
		font-size: 12px;
		margin-bottom: 5px;
		grid-gap: 0;
		gap: 0;
	}

	> p {
		@include mq(mobile, tablet) {
			font-size: 13px;
		}
		&:nth-of-type(1) {
			font-weight: 700;
		}
	}
}

.lote-action-buttons {
	display: grid;
	grid-template-columns: 1fr 1fr;

	@include mq(mobile, tablet) {
		grid-template-columns: 1fr;
		margin-bottom: 1rem;
	}

	@include mq(tablet, desktop) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		//padding-top: 15px;
		gap: 6px;
		width: 100%;
	}

	@include mq(desktop, wide) {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin: auto;
		align-items: center;
		//padding-top: 15px;
		gap: 1rem;
	}

	@include mq(wide) {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		margin: auto;
		padding-top: 15px;
		gap: 1rem;
	}
}

.btn-lote {
	text-align: center;
	padding: 0.6rem 0;
	color: white;
	border-radius: 5px;
	font-size: 1rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 0.3s ease-in-out;

	@include mq(mobile, tablet) {
		font-size: 13px;
		font-weight: 600;
	}

	@include mq(tablet, wide) {
		width: 80%;
		font-size: 11px;
	}

	@include mq(wide) {
		width: 55%;
	}
}

.btn-lote-show-more {
	color: #19513C;
	text-decoration: underline;
	text-transform: uppercase;

	&:hover {
		text-decoration: none;
	transition: all 0.3s ease-in-out;
	}
	@include mq(tablet, wide) {
		font-size: 10px;
		padding-top: 10px;
	}

	@include mq(wide) {
		font-size: 14px;
	}
}

.btn-lote-offer {
	background-color: #FF9900;
	&:hover {
		background-color: darken(#FF9900, 10%);
	}
}

.btn-lote-disabled {
	background-color: lightgrey;
	pointer-events: none;
}

.no-padding {
	padding: 0 !important;
}

.timer-only-wrapper {
	color: #545454;
	font-weight: 600;
	text-align: left;
	flex: 1;
	font-size: 1.3rem;
	box-sizing: "border-box";

	@include mq(mobile, tablet) {
		font-size: 18px;
	}
	@include mq(tablet, desktop) {
		font-size: 15px;
	}
	@include mq(desktop, wide) {
		font-size: 1.1rem;
	}
}

.timer-mobile-wrapper {
	color: #545454;
	font-weight: 700;
	text-align: center;
	flex: 1;
	font-size: 1.3rem;
	box-sizing: "border-box";

	@include mq(mobile, tablet) {
		font-size: 18px;
	}
	@include mq(tablet, desktop) {
		font-size: 12px;
	}
	@include mq(desktop, wide) {
		font-size: 1.1rem;
	}
}

.lote-finished {
	color: #F96161;
}

.timer-wrapper {
	font-weight: 700;
	text-align: center;
	flex: 1;
	box-sizing: "border-box";

	@include mq(mobile, tablet) {
		margin-left: 5%;
		color: #545454;
	}

	@include mq(tablet, desktop) {
		font-size: 12px;
		color: #FFE3B8;
	}

	@include mq(desktop, wide) {
		font-size: 1.1rem;
		color: #FFE3B8;
	}

	@include mq(wide) {
		font-size: 1.3rem;
		color: #FFE3B8;
	}
}

.tablet-desktop-wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin-bottom: 1rem;
	@include mq(tablet, desktop) {
		grid-template-columns: 0.8fr 1fr 1fr;
		grid-gap: 5px;
		margin-bottom: 0;
		align-items: center;
	}
}

.lote-information-container {
	padding: 0 1rem;
	margin-top: 1rem;
	@include mq(mobile, tablet) {
		padding: 0;
	}
}
