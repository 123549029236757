$myColor: grey;
$verdeOscuro: #19513C;

$statusRed: #F96161;
$statusGreen: #66A38C;
$statusGrey: #BDBDBD;
$black: #545454;

$bp_imac: 1080px;
$bp_ipad: 834px;
$bp_iphone: 428px;
$gris: #545454;
$grisClaro: #EEE;
$negro: $black;

$naranja: #FF9900;

$s1:8px;
$s2:16px;
$s3:24px;
$s4:32px;
$s5:40px;
$s6:48px;
$s7:56px;
$s8:64px;
$s9:72px;
$s10:80px;
$s11:88px;
$s12:96px;
$s13:104px;
$s14:112px;
$s15:120px;
$s16:128px;




*{
  padding: 0;
  margin: 0;
}

body{
  font-family: 'DM Sans', sans-serif;
  background-color: #EEEEEE;
  color: $black;
}


h2{
  color: white;
  font-size: 72px;
  line-height: 120%;
  @media screen and (max-width: $bp_imac){
    font-size: 56px;
    line-height: 120%;
  }
}

.notFound{
  height: 80vh;
}

button{
  border: none;
}
.breadcrum{
  width: 100%;
  text-align: left;
  position: fixed;
  background-color: #9fb5ad;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 3;
  top: 115px;
	left: 0;
  color: white;
  padding-left: 32px;
  box-sizing: border-box;
  font-size: 14px;
}
