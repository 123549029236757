hr {
	width: 100%;
}
.navbar2{
  &_desplegable{
    &-disabled{
      display: none;
    }
    &-punta{
      position: absolute;
      top: -16px;
      right: 6px;
      width: 30px;
      height: 30px;
      background-color: $verdeOscuro;
      transform: rotate(45deg);
    }
    background-color: $verdeOscuro;
    position: absolute;
    display: flex;
    flex-direction: column;
		align-items: center;
    justify-content: space-between;
    top: 110px;
    padding: 20px;
    color: white;
    right: 35px;
    width: 200px;
    z-index: 999;
		gap: 12px;
    border-radius: 16px 0 16px 16px;
    &_superior{
      display: flex;
			align-items: center;
			text-align: center;
      flex-direction: column;
      gap: 16px;
    }
		hr {
			margin: 10px 0;
		}
    h2{
      font-size: 20px;
			text-align: center;
			word-break: break-all;
    }
  }
  &_userBtn{
		padding: 10px 0 !important;
		height: initial !important;
  }
  &_avatar{
    width: 50px;
    height: 50px;
    margin-top: 15px;
    background-color: $naranja;
    border-radius: 50%;
    color: white;
    box-sizing: border-box;
    //padding-left: 17px;
    padding-top: 8px;
    font-size: 25px;
    margin-left: 26px;
    text-align: center;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
    &-offline{
      background-color: #bbb;
    }
  }
}



.navbar2_flex{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.navbar2_logo{
  height: 84px;
  margin: 0;
  img{
    height: 100%;
  }
}
.breadcrum{
  top: 84px;
  opacity: 0.8;
  padding-left: 48px !important;
	@include mq(mobile, tablet) {
		padding-left: 5px !important;
	}
}

.navbar2_menu{
  color: white;
  padding-top: 32px;
  font-size: 14px;
  &_burger{
    padding-top: 28px;
    display: none;
		@include mq(mobile, desktop) {
      display: block;
    }
  }
  &-active{
    @include mq(mobile, desktop){
      display: block !important;
    }
  }
  @include mq(mobile, desktop) {
    display: none;
    position: absolute;
    background-color: #103e2d;
    top: 84px;
    left: 0;
    width: 100%;
    padding: 16px;
    padding-left: 0px;
  }
  ul{
    display: flex;
		align-items: center;
		@include mq(mobile, tablet) {
			text-align: center;
			display: block;
		}
		@include mq(tablet, desktop) {
			text-align: center;
			display: block;
		}
    @media screen and (max-width: $bp_ipad){
      justify-content: center;

    }
    li{
      list-style: none;
      margin-right: 16px;
      margin-left: 16px;
      align-self: center;
			padding-bottom: 8px;
			@include mq(mobile, tablet) {
				padding-bottom: 0;
				margin-left: 0;
				margin-right: 0;
				margin-bottom: 15px;
			}
			> a {
				@include mq(mobile, tablet) {
					display: block;
					font-size: 16px;
					padding: 8px 0 !important;
				}
				@include mq(tablet, desktop) {
					display: block;
					font-size: 16px;
					padding: 8px 0 !important;
				}
			}
    }
  }
}

.navbar2_menu_flex{
  display: flex;
}

.litle-space-menu {
	@include mq(mobile, tablet) {
		padding-bottom: 0;
		font-size: 16px;
	}
}

.li-submenu {
	display: flex;
	align-items: center;
	gap: 5px;
	@include mq(mobile, tablet) {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding-top: 8px !important;
		padding-bottom: 8px !important;
	}
}
