.ficha{
  box-sizing: border-box;
  border-radius: 16px;
  max-width: 1200px;
  background-color: white;
  height: auto;
  margin: auto;
  margin-top: 24px;
	@include mq(mobile, tablet) {
		width: 100%;
	}
	@include mq(tablet, desktop) {
		width: 90%;
	}
	@include mq(desktop, wide) {
		width: 80%;
	}
	@include mq(wide) {
		width: 70%;
	}

  &_timer{
    background-color: $verdeOscuro;
    margin-left: -54px;
    margin-right: -54px;
    text-align: center;
    color: white;
    height: 32px;
    padding-top: 6px;
    box-sizing: border-box;
    &-close{
      background-color: $statusRed !important;
    }
  }
  &_info{
    padding: 12px 0;
    text-align: center;
		font-weight: 600;
    background-color: $verdeOscuro;
    color: white;
		border-radius: 3px 3px 0 0;
    &-close{
      background-color: $statusRed;
    }
    &-proximamente{
      background-color: $statusGrey;
    }
    &-open{
      background-color: $statusGreen;
    }
		@include mq(mobile, tablet) {
			font-size: 15px;
		}
		@include mq($from: tablet) {
			font-size: 20px;
		}
  }
  &_data{
    display: grid;
    grid-template-columns: 1fr 1fr;

		@include mq(mobile, tablet) {
			grid-template-columns: 1fr;
			gap: 1.5rem;
		}
		@include mq($from: tablet) {
			gap: 2rem;
		}
    &_timer{
      grid-area: timer;
      background-color: $statusGreen;
      color: white;
      text-align: center;
      padding-top: 4px;
      box-sizing: border-box;
      height: 32px;

    }
    &_img{
      width: 100%;
			> img {
				width: 100%;
				margin: 0 auto;
			}
    }
    &_text{
      display: flex;
			flex-direction: column;
      &_nombre{

        h5{
          margin-top: 16px;
					@include mq(mobile, tablet) {
						font-size: 30px;
					}
					@include mq($from: tablet) {
          font-size: 34px;
				}
        }
      }
      &_puja{
				margin: 10px 0;
      }
      &_oferta{
        h5{
          color: $naranja;
        }
      }
    }
  }
  &_wrapp{
    margin-top: 96px;
    padding: 16px;
    display: flex;
  }
  &_titulo{
    color: #545454;
    &_codigo{
      font-size: 16px;
      &_dato{
        font-size: 24px;
        color: #FF9900;
        opacity: 1;
        margin-top: 8px;
      }
    }
    &_titular{
      font-size: 35px;
      margin-top: 16px;
      font-weight: bold;
    }
  }
  &_datosPrincipales{
    display: flex;
    flex-wrap: wrap;
		margin-top: 3rem;
		@include mq(mobile, tablet) {
			padding: 0 10px;
		}
    //gap: 8px;
    &-cajas{
      width: 290px;
      min-width: 200px;
      margin-top: 16px;
      &_titulos{
        color: #FF9900;
        font-weight: bold;
      }
      &_datos{
        color: #545454;
        margin-top: 8px;
      }
    }
  }
  &_datosSecundarios{
    border: 1px solid #ddd;
    border-width: 1px 1px 1px 1px;
    width: 100%;
    display: grid;
    //flex-wrap: wrap;
		//justify-content: space-evenly;
		//gap: 18px 0;
		//padding-top: 15px;
		//padding-bottom: 15px;
		border-bottom: 0;

		&:first-child {
			border-radius: 16px 16px 0 0;
		}
		&:last-child {
			border-bottom: 1px solid #ddd;
		}

		@include mq(mobile, tablet) {
			text-align: center;
			grid-template-columns: 1fr 1fr;
			padding: 1rem;
			grid-gap: 1rem 3rem;
		}
		@include mq(tablet, desktop) {
			grid-template-columns: 1fr 1fr;
			padding: 1rem;
			grid-gap: 1rem 3rem;
		}

		@include mq(desktop, wide) {
			grid-template-columns: 1fr 1fr 1fr;
			padding: 1rem;
			grid-gap: 1rem 3rem;
		}

		@include mq(wide) {
			grid-template-columns: 1fr 1fr 1fr;
			padding: 1rem;
			grid-gap: 1rem 3rem;
		}
    //:last-child{
    //  flex-grow: 2.5;
    //}
    &_cajas{
			flex: 0 1 30%;
      //border: 1px solid #ddd;
      border-width: 0 0 1px 0;
      @media screen and (max-width: $bp_imac){
        max-width: 100%;
      }
      &_titulo{
        color: #19513C;
        font-weight: bold;
				font-size: 12px;

				@include mq(mobile, tablet) {
					font-size: 13px;
					margin-bottom: 5px;
				}
				@include mq($from: tablet) {
					font-size: 16px;
				}
      }
      &_dato{
        color: $statusGreen;
        font-weight: normal;
        background-color: white !important;
				text-transform: uppercase;

				@include mq(mobile, tablet) {
					font-size: 12px;
					width: 70%;
					margin: auto;
				}
				@include mq($from: tablet) {
					font-size: 18px;
				}
      }
    }
  }
  &_stats{
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    &_caja{
      margin: 16px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      color: #545454;
      &_img{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: black;
      }
      &_titulo{
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin-top: 16px;
				margin-bottom: 1rem;
      }
      &_texto{
        margin-top: 16px;
        text-align: center;
        font-size: 14px;
        opacity: 0.5;
        text-transform: uppercase;
        line-height: 1.6;

        @media screen and (max-width: $bp_iphone){
          padding: 32px;
          font-size: 12px;
        }
      }
    }
  }
  &_galeria{
    width: 100%;
    margin-top: 32px;
    &_titulo{
      color: #545454;
      font-weight: bold;
      text-align: center;
      font-size: 20px;
    }
    &_caja{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;
      margin-top: 24px;
      &_foto{
        width: 40%;
        @media screen and (max-width: $bp_imac){
          width: 70%;

        }
        img{
          width: 100%;
        }
      }
    }
  }
  &_botonera{
    display: flex;
    justify-content: center;
    margin: 48px;
  }

}


.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}
.mass{
  width: 100%;
}

.ficha_data_timer-close{
  background-color: $statusRed !important;
}
.ficha_data_timer-proximamente{
  background-color: $statusGrey !important;
}

.content-spacing {
	width: 95%;
	margin: 1rem auto;
}
